import { Injectable } from '@angular/core';
import { Observable, from, of, map } from 'rxjs';
import { contactObj, ltlBookingObj } from '../../interfaces';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertService, AmplifyApiGWService, DataService } from '..';
import { Amplify } from 'aws-sdk';

@Injectable({
  providedIn: 'root',
})
export class ZipcodeService {
  private url: string = 'https://secure.shippingapis.com/ShippingAPI.dll?';
  private userID = '703GLOBA5797';

  constructor(
    private zipWS: HttpClient,
    private alertService: AlertService,
    private ajax: AmplifyApiGWService,
    private dataService: DataService
  ) {}

  async getValueByZip(
    zipInput: string
  ): Promise<{ city: string; state: string; zip: string; success: boolean; errorType: string }> {
    // Preprocess the ZIP code input
    let zipCode = zipInput.replace(/\s/g, '');
    if (zipCode.length > 5 || zipCode.includes('-')) {
      zipCode = zipCode.substring(0, 5).split('-')[0];
    }
  
    // Initialize the result structure
    let result = {
      city: '',
      state: '',
      zip: zipCode,
      success: false,
      errorType: '',
    };
  
    // Only proceed if the ZIP code is exactly 5 digits
    if (zipCode.length === 5) {
      try {
        // Perform the fetch operation within the same function
        const response = await fetch(
          this.url +
            'API=CityStateLookup&XML=' +
            encodeURIComponent(
              `<?xml version="1.0" encoding="UTF-8"?>
        <CityStateLookupRequest USERID='${this.userID}'>
        <ZipCode ID='0'>
        <Zip5>${zipCode}</Zip5>
        </ZipCode>
        </CityStateLookupRequest>`.replace(/[\t\n]/g, '')
            )
        );
  
        if (!response.ok) throw new Error('Network response was not ok.');
        const data = await response.text();
  
        // Parse the XML response
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(data, 'text/xml');
        // Attempt to extract city, state, and mark success as true
        result.city =
          xmlDoc.getElementsByTagName('City')[0]?.childNodes[0]?.nodeValue ||
          '';
        result.state =
          xmlDoc.getElementsByTagName('State')[0]?.childNodes[0]?.nodeValue ||
          '';
        result.success = !!result.city && !!result.state; // Ensure both city and state are extracted successfully
  
        if (!result.success) {
          result.errorType = 'zipCodeError'; // Set errorType if ZIP code is invalid
        }
      } catch (error) {
        // If an error occurs, log it and set errorType to 'serviceError'
        console.error('Error fetching or parsing zip code data:', error);
        result.errorType = 'serviceError';
      }
    } else {
      // Set errorType if the ZIP code is not exactly 5 digits
      result.errorType = 'zipCodeError';
    }
  
    return result;
  }

  async USPSvalidateAddress({
    address1,
    address2,
    city,
    state,
    postal_code,
  }: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    postal_code: string;
  }): Promise<{
    address: {
      address1: string;
      address2: string;
      city: string;
      state: string;
      postal_code: string;
    };
    success: boolean;
  }> {
    // Construct XML for API request
    const xmlRequest = encodeURIComponent(
      `<AddressValidateRequest USERID='${this.userID}'>
        <Revision>1</Revision>
        <Address ID='0'>
          <Address1>${address1}</Address1>
          <Address2>${address2}</Address2>
          <City>${city}</City>
          <State>${state}</State>
          <Zip5>${postal_code}</Zip5>
          <Zip4/>
        </Address>
      </AddressValidateRequest>`.replace(/[\t\n]/g, '')
    );

    try {
      // Perform the API call
      // console.log('Request: ', this.url + `API=Verify&XML=${xmlRequest}`);
      const response = await fetch(this.url + `API=Verify&XML=${xmlRequest}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.text();
      // console.log('Response: ', data);

      // Parse the XML response
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(data, 'text/xml');

      // Check if the first child node of 'Address' is an 'Error' element
      const addressNode = xmlDoc.getElementsByTagName('Address')[0];
      const isValid = !(addressNode.firstChild?.nodeName === 'Error');

      let new_address1 = '';
      let new_address2 = '';
      let new_city = '';
      let new_state = '';
      let new_zip5 = '';
      let new_zip4 = '';
      let new_zip = '';

      if (isValid) {
        new_address1 = addressNode.getElementsByTagName('Address2')[0]?.textContent || '';
        new_address2 = addressNode.getElementsByTagName('Address1')[0]?.textContent || '';
        new_city = addressNode.getElementsByTagName('City')[0]?.textContent || '';
        new_state = addressNode.getElementsByTagName('State')[0]?.textContent || '';
        new_zip5 = addressNode.getElementsByTagName('Zip5')[0]?.textContent || '';
        new_zip4 = addressNode.getElementsByTagName('Zip4')[0]?.textContent || '';
        new_zip = new_zip5 + (new_zip4 ? '-' + new_zip4 : '');
      } else {
        new_address1 = address1;
        new_address2 = address2;
        new_city = city;
        new_state = state;
        new_zip = postal_code;
      }

      return {
        address: { address1: new_address1, address2: new_address2, city: new_city, state: new_state, postal_code: new_zip },
        success: isValid,
      };
    } catch (error) {
      console.error('Validation failed:', error);
      // In case of an error (e.g., network error), return the address and success=false
      return {
        address: { address1, address2, city, state, postal_code },
        success: false,
      };
    }
  }

  // Helper function to check if an address has changed
  isAddressChanged(validatedAddress: any, currentAddress: any): boolean {
    return (
      validatedAddress.address.address1 !== currentAddress.address ||
      validatedAddress.address.address2 !== currentAddress.address2 ||
      validatedAddress.address.city !== currentAddress.city ||
      validatedAddress.address.state !== currentAddress.state ||
      validatedAddress.address.postal_code !== currentAddress.postal_code
    );
  }

  validateAndSaveAddress(address: contactObj, custIDString: string, parentCustIDString: string): Observable<any> {
    if (!address.address) {
      return of({
        address: address,
        validated: false,
      });
    }

    return from(
      this.USPSvalidateAddress({
        address1: address.address,
        address2: '',
        city: address.city,
        state: address.state,
        postal_code: address.postal_code,
      })
    ).pipe(
      map((response: any) => {
        if (response.success) {
          // Create a new object with updated address and city from the response
          let newAddress = {
            ...address, // spread operator to copy all properties of address
            // update address from response
            address:
              response.address.address2 !== ''
                ? `${response.address.address1}, ${response.address.address2}`
                : response.address.address1,
            city: response.address.city, // update city from response
            state: response.address.state, // update city from response
            zip: response.address.postal_code.length > 5 ? response.address.postal_code.substring(0, 5).split('-')[0] : response.address.postal_code, // update city from response
          };
          from(this.ajax.callApi(
            'catalogs/entities',
            'POST',
            {},
            {
              name: address.companyname,
              address: newAddress.address,
              postal_code: newAddress.postal_code,
              city: newAddress.city,
              state: newAddress.state,
              country: 'USA',
              residential: false,
              contact_name: address.contactname,
              contact_phone: address.phone,
              contact_email: address.email,
              cust_ids: custIDString,
              parent_cust_ids: parentCustIDString,
              validated: true,
            }
          )).subscribe(
            (result) => {
              // console.log('Add Entity result: ', result);
            }
          );
          // Return the new address object
          return {
            address: newAddress,
            validated: true,
          };
        } else {
          // Return the original address object unchanged
          return {
            address: address,
            validated: false,
          };
        }
      })
    );
  }

  async validateAddress(
    addressObj: any,
    type: string
  ): Promise<boolean> {
    const result = await this.validateAndHandleAddressChange(
      addressObj,
      type.toLowerCase()
    );
    if (!result.valid) {
      if (!result.use) {
        await this.alertService.showAlert(
          result.message + ' Please fix the address.'
        );
      } else {
        await this.alertService.showAlert(
          result.message + ' Using the address as is. Extra charges may apply.'
        );
      }
      return false;
    }
    return true;
  }
  
  async validateAndHandleAddressChange(
    addressObj: any,
    addressType: string
  ): Promise<any> {
    addressObj.postal_code = addressObj.postal_code.substring(0, 5); // Take only the first five digits of the zip

    if (
      !addressObj.address ||
      !addressObj.city ||
      !addressObj.state ||
      !addressObj.postal_code
    ) {
      const addressMessage = `${
        addressType.charAt(0).toUpperCase() + addressType.slice(1)
      } address is incomplete.`;
      return {
        valid: false,
        use: false,
        message: addressMessage,
      };
    }

    const addressResult = await this.USPSvalidateAddress({
      address1: addressObj.address,
      address2: addressObj.address2 || '',
      city: addressObj.city,
      state: addressObj.state,
      postal_code: addressObj.postal_code,
    });

    if (
      !addressResult.address.address2 ||
      addressResult.address.address2.trim().toLowerCase() === 'undefined'
    ) {
      addressResult.address.address2 = '';
    }

    if (addressResult.success === false) {
      const addressAccepted = await this.alertService.showConfAlert(
        `Confirm ${
          addressType.charAt(0).toUpperCase() + addressType.slice(1)
        } address.`,
        `${
          addressType.charAt(0).toUpperCase() + addressType.slice(1)
        } address could not be validated. Please confirm you want to use this address (extra charges may apply) or return to form to fix.`
      );
      return {
        valid: false,
        use: addressAccepted,
        message: `${
          addressType.charAt(0).toUpperCase() + addressType.slice(1)
        } address could not be validated.`,
      };
    }

    // take only the first 5 digits of addressResult.address.postal_code
    addressResult.address.postal_code =
      addressResult.address.postal_code.substring(0, 5);

    const addressChanged = this.isAddressChanged(addressResult, addressObj);
    if (addressChanged) {
      const addressAccepted = await this.alertService.confirmAddressChange(
        addressObj,
        addressResult.address,
        addressType
      );
      if (!addressAccepted) {
        const addressAccepted = await this.alertService.showConfAlert(
          `Confirm ${
            addressType.charAt(0).toUpperCase() + addressType.slice(1)
          } address.`,
          `Suggested ${
            addressType.charAt(0).toUpperCase() + addressType.slice(1)
          } address changes not accepted. Please confirm you want to use this address (extra charges may apply) or return to form to fix.`
        );
        return {
          valid: false,
          use: addressAccepted,
          message: `${
            addressType.charAt(0).toUpperCase() + addressType.slice(1)
          } address changes not accepted.`,
        };
      }
    }
    if (addressChanged) {
      this.updateAddress(addressObj, addressResult.address);
      this.saveAddressToDatabaseAsync(addressObj);
    }

    return {
      valid: true,
      use: true,
      message: 'Address is valid.',
    };
  }

  saveAddressToDatabaseAsync(addressObj: any): void {
    try {
      console.log('Saving address to database:', addressObj);
      setTimeout(() => {
        this.dataService.saveAddressToDatabase(
          addressObj);
      }, 0);
    } catch (error) {
      console.error('Error saving the address:', error);
    }
  }

  // Helper function to update the address object
  updateAddress(targetObj: any, validatedAddress: any) {
    targetObj.address = validatedAddress.address1;
    targetObj.address2 = validatedAddress.address2;
    targetObj.city = validatedAddress.city;
    targetObj.state = validatedAddress.state;
    targetObj.postal_code = validatedAddress.postal_code;
  }
}
