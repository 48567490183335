<ion-header>
  <ion-toolbar class="fdAddressConfToolbar">
    <ion-title class="fdSendRQTitle">Confirm Address Change</ion-title>
    <ion-buttons slot="end">
      <ion-button class="fdSendRQButton" shape="round" fill="solid" color="dark" (click)="reject()">Cancel</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <!-- Old Address Column -->
      <ion-col size="6">
        <div class="address-header">
          Provided Address:
        </div>
        <p>{{ oldAddress.address }}</p>
        <p *ngIf="oldAddress.address2">{{ oldAddress.address2 }}</p>
        <p>{{ oldAddress.city }}, {{ oldAddress.state }} {{ oldAddress.postal_code }}</p>
      </ion-col>

      <!-- New Address Column -->
      <ion-col size="6">
        <div class="address-header">
          Suggested Address:
        </div>
        <p>{{ newAddress.address1 }}</p>
        <p *ngIf="newAddress.address2">{{ newAddress.address2 }}</p>
        <p>{{ newAddress.city }}, {{ newAddress.state }} {{ newAddress.postal_code }}</p>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar class="fdAddressConfToolbar">
    <ion-buttons slot="start">
      <ion-button class="fdSendRQButton" shape="round" fill="outline" color="danger" (click)="reject()">Reject
        Changes</ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button class="fdSendRQButton" shape="round" fill="outline" color="success" (click)="accept()">Accept
        Changes</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>