import { Injectable } from '@angular/core';
import {
  objItem,
  ltlBookingObj,
  item,
  objAddress,
  EntityInfo,
  contactObj,
} from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class DefaultDataService {
  private handingUnitItem: string[] = [
    'Pallets',
    'Bags',
    'Bundles',
    'Boxes',
    // 'Cabinets',
    // 'Cans',
    // 'Cases',
    'Crates',
    'Cartons',
    'Cylinders',
    'Drums',
    'Pails',
    // 'Pieces',
    // 'Flat Racks',
    // 'Reels',
    'Rolls',
    // 'Skids',
    // 'Slip Sheets',
    // 'Stacks',
    // 'Totes',
  ];

  private packageItem: string[] = [
    'Cartons',
    'Bags',
    'Bundles',
    'Boxes',
    'Cabinets',
    'Cans',
    'Cases',
    'Crates',
    'Cylinders',
    'Drums',
    'Pails',
    'Pallets',
    'Pieces',
    'Flat Racks',
    'Reels',
    'Rolls',
    'Skids',
    'Slip Sheets',
    'Stacks',
    'Totes',
  ];

  private classOptions: string[] = [
    ' - ',
    '500',
    '400',
    '300',
    '250',
    '200',
    '175',
    '150',
    '125',
    '110',
    '100',
    '92.5',
    '85',
    '77.5',
    '70',
    '65',
    '60',
    '55',
    '50',
  ];

  private alertOptions: any[] = [
    ['All', 'All'],
    ['overdue', 'OVERDUE'],
    ['arriving_soon', 'ARRIVING SOON'],
    ['on_track', 'ON TRACK'],
    ['completed', 'COMPLETED'],
  ];

  public statusList: string[] = [
    'All',
    'COMPLETED',
    'CANCELLED',
    'COVERED',
    'DELIVERED',
    'IN TRANSIT',
    'LOADED',
    'OPEN',
  ];

  private serviceItems: any[] = [
    {
      label: 'Residential Pickup',
      value: 'RES2',
    },
    {
      label: 'Residential Delivery',
      value: 'RES1',
    },
    {
      label: 'Inside Pickup',
      value: 'IPU',
    },
    {
      label: 'Inside Delivery',
      value: 'IDL',
    },
    {
      label: 'Notify',
      value: 'APPT',
    },
    {
      label: 'COD',
      value: 'COD',
    },
    {
      label: 'Limited Access/Residential',
      value: 'LADAIRP',
    },
    {
      label: 'Protect and Freeze',
      value: 'PROT',
    },
    {
      label: 'Keep from Freezing',
      value: 'KeepFromFreezing',
    },
    {
      label: 'Sort And Segregate',
      value: 'STSG',
    },
    {
      label: 'Liftgate Pickup',
      value: 'LG2',
    },
    {
      label: 'Liftgate Delivery',
      value: 'LG1',
    },
    {
      label: 'Hazardous Material',
      value: 'HAZM',
    },
  ];

  private spServiceCodes: any[] = [
    { label: 'Next Day Air', value: '01' },
    { label: '2nd Day Air', value: '02' },
    { label: 'Ground', value: '03' },
    { label: 'Worldwide Express', value: '07' },
    { label: 'Worldwide Expedited', value: '08' },
    { label: 'Standard', value: '11' },
    { label: '3 Day Select', value: '12' },
    { label: 'Next Day Air Saver', value: '13' },
    { label: 'Next Day Air Early AM', value: '14' },
    { label: 'Worldwide Express Plus', value: '54' },
    { label: '2nd Day Air AM', value: '59' },
    { label: 'Saver', value: '65' },
  ];

  private spPickupTypes: any[] = [
    { label: 'Daily Pickup', value: '01' },
    { label: 'Customer Counter', value: '03' },
    { label: 'One Time Pickup', value: '06' },
    { label: 'On Call Air', value: '07' },
    { label: 'Letter Center', value: '19' },
    { label: 'Air Service Center', value: '20' },
  ];

  private refTypeLists: any = {
    WWE: [
      { type: 'AC', description: 'Accounting Code' },
      { type: 'BOL', description: 'Bill of Lading Number' },
      { type: 'BN', description: 'Booking Number' },
      { type: 'CA', description: 'Carton Number' },
      { type: 'CD', description: 'Charge Department' },
      { type: 'CR', description: 'Creator' },
      { type: 'CT', description: 'Contract Number' },
      { type: 'CC', description: 'Cost Center' },
      { type: 'CS', description: 'Customer Number' },
      { type: 'CO', description: 'Customer Order Number' },
      { type: 'DJ', description: 'Delivery Number' },
      { type: 'FA', description: 'Fractura Number' },
      { type: 'FC', description: 'Freight Collected' },
      { type: 'RN', description: 'General Ref Number' },
      { type: 'LO', description: 'Loading Number' },
      { type: 'MN', description: 'Manifest Number' },
      { type: 'PN', description: 'Pedimento Number' },
      { type: 'PD', description: 'Promotional Deal' },
      { type: 'PO', description: 'Purchase Order Number' },
      { type: 'E', description: 'Requested Arrival Date' },
      { type: 'QJ', description: 'Return Authorization' },
      { type: 'SN', description: 'Seal Number' },
      { type: 'IV', description: 'Sellers Invoice Number' },
      { type: 'TN', description: 'Transportation Control Number' },
    ],
    DEFAULT: [
      { type: 'AC', description: 'Accounting Code' },
      { type: 'BOL', description: 'Bill of Lading Number' },
      { type: 'BN', description: 'Booking Number' },
      { type: 'CA', description: 'Carton Number' },
      { type: 'CD', description: 'Charge Department' },
      { type: 'CR', description: 'Creator' },
      { type: 'CT', description: 'Contract Number' },
      { type: 'CC', description: 'Cost Center' },
      { type: 'CS', description: 'Customer Number' },
      { type: 'CO', description: 'Customer Order Number' },
      { type: 'DJ', description: 'Delivery Number' },
      { type: 'FA', description: 'Fractura Number' },
      { type: 'FC', description: 'Freight Collected' },
      { type: 'RN', description: 'General Ref Number' },
      { type: 'LO', description: 'Loading Number' },
      { type: 'MN', description: 'Manifest Number' },
      { type: 'PN', description: 'Pedimento Number' },
      { type: 'PD', description: 'Promotional Deal' },
      { type: 'PO', description: 'Purchase Order Number' },
      { type: 'E', description: 'Requested Arrival Date' },
      { type: 'QJ', description: 'Return Authorization' },
      { type: 'SN', description: 'Seal Number' },
      { type: 'IV', description: 'Sellers Invoice Number' },
      { type: 'TN', description: 'Transportation Control Number' },
    ],
  };

  systemToButtonLabels: { [key: string]: string } = {
    WWE: 'BOOK<br />SHIPMENT',
    'TForce Worldwide': 'REQUEST<br />SHIPMENT',
    Cerasis: 'REQUEST<br />SHIPMENT',
    'Estes-RL': 'REQUEST<br />SHIPMENT',
    'Estes-UC': 'REQUEST<br />SHIPMENT',
    'R+L Carriers-ECOMV2': 'REQUEST<br />SHIPMENT',
    'R+L Carriers-CC': 'REQUEST<br />SHIPMENT',
    'RIST Transport': 'REQUEST<br />SHIPMENT',
    'Daylight-FS': 'REQUEST<br />SHIPMENT',
    'Daylight-Z': 'REQUEST<br />SHIPMENT',
    'Daylight-USCS': 'REQUEST<br />SHIPMENT',
    'A. Duie Pyle': 'REQUEST<br />SHIPMENT',
  };

  private systemCodes: any = {
    WWE: 'WW',
    'TForce Worldwide': 'TF',
    Cerasis: 'CE',
  };

  public informationFromForm: objAddress[] = [
    {
      Address: '',
      zipCode: ['', '', ''],
      services: [],
      date: new Date(new Date().setHours(1)).toISOString().slice(0, 10),
      country: 'USA',
      addressName: '',
    },
    {
      Address: '',
      zipCode: ['', '', ''],
      services: [],
      date: '',
      country: 'USA',
      addressName: '',
    },
  ];

  public emptyEntity: EntityInfo = {
    name: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    postal_code: '',
    country: 'USA',
    isResidential: false,
  };

  public emptyContact: contactObj = {
    companyname: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    postal_code: '',
    country: 'USA',
    isResidential: false,
    contactname: '',
    phone: '',
    email: '',
  };

  public emptyLTLBooking: ltlBookingObj = {
    rqkey: '',
    booking_key: '',
    booking_status: 'NEW',
    bol_number: '',
    pricing_program: '',
    username: '',
    useremail: '',
    fullname: '',
    parent_cust_id: '',
    cust_id: '',
    companyname: '',
    supportemail: '',
    ccemails: [],
    origin: {
      companyname: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
      isResidential: false,
      contactname: '',
      phone: '',
      email: '',
    },
    dest: {
      companyname: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
      isResidential: false,
      contactname: '',
      phone: '',
      email: '',
    },
    billto: {
      companyname: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
      isResidential: false,
      contactname: '',
      phone: '',
      email: '',
    },
    items: [],
    accessorials: [],
    specialinst: '',
    pickupInst: '',
    deliveryInst: '',
    pickupDate: '',
    pickuptime: '',
    closetime: '',
    todate: '',
    system: '',
    carriername: '',
    servicelevel: '',
    servicecode: '',
    carrierscac: '',
    transitdays: 0,
    quotenumber: '',
    cost: 0,
    charge: 0,
    insrequested: false,
    insValue: 0,
    chargewithinsurance: 0,
    references: [],
    reqPltLabels: false,
    sendBOLtoShipper: false,
    sendBOLtoConsignee: false,
    billToReceiver: false,
    receiverAcctNum: '',
  };

  constructor() {}

  getSystemCodes(): any {
    return this.systemCodes;
  }

  getHandlingUnitItem(): string[] {
    return this.handingUnitItem;
  }

  getPackageItem(): string[] {
    return this.packageItem;
  }

  getButtonLabel(system: string): string {
    return this.systemToButtonLabels[system] || 'REQUEST SHIPMENT';
  }

  getClassOptions(): string[] {
    return this.classOptions;
  }

  getAlertOptions(): any[] {
    return this.alertOptions;
  }

  getStatusList(): string[] {
    return this.statusList;
  }

  getServiceItems(): any[] {
    return this.serviceItems;
  }

  getSPServiceCodes(): any[] {
    return this.spServiceCodes;
  }

  getSPPickupTypes(): any[] {
    return this.spPickupTypes;
  }

  getInformationFromForm(): objAddress[] {
    return this.informationFromForm;
  }

  setEntity(): EntityInfo {
    return this.emptyEntity;
  }

  setContact(): contactObj {
    return this.emptyContact;
  }

  setItem(): objItem {
    return {
      unit: 'IN',
      len: 40,
      width: 48,
      height: 48,
      handlingUnit: 'Pallets',
      numUnits: 0,
      weight: 0,
      pkgType: 'Cartons',
      numPkgs: 0,
      class: null,
    };
  }

  setRQItem(): item {
    return {
      unit: 'IN',
      description: '',
      hazmat: false,
      hazmatcode: '',
      nmfccode: '',
      len: 40,
      width: 48,
      height: 48,
      handlingUnit: 'Pallets',
      numUnits: 0,
      weight: 0,
      pkgType: 'Cartons',
      numPkgs: 0,
      class: null,
      density: 0,
    };
  }

  setLTLBooking(): ltlBookingObj {
    return this.emptyLTLBooking;
  }

  getRefTypes(carrier: string): any[] {
    return this.refTypeLists[carrier];
  }
}
