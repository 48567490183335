import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  AmplifyApiGWService,
  AlertService,
  AuthenticationService,
  BOLGeneratorService,
  DataService,
  DefaultDataService,
  LtlEmailService,
  ZipcodeService,
} from '../services';
import {
  contactObj,
  custInfo,
  ShipReference,
  BookingRef,
  ltlBookingObj,
  EntityInfo,
  objItem,
  item,
  UserInfo,
  RateQuote,
} from '../interfaces';
import { LTL_BOL_PAGE_CONFIG, SystemType } from '../config';

@Injectable({
  providedIn: 'root',
})
export class LtlBookService {
  private readyToBookSubject = new BehaviorSubject<boolean>(false);
  private ltlBookingObjSubject = new BehaviorSubject<ltlBookingObj>(
    {} as ltlBookingObj
  );

  inputsValid: boolean = false;
  errorMessages: string[] = [];
  validSystemTypes: string[] = ['WWE', 'DEFAULT'];

  subscriptions: Subscription[] = [];
  currentLTLBooking: ltlBookingObj = {} as ltlBookingObj;
  isOriginPhoneRequired: boolean = true;
  isDestPhoneRequired: boolean = true;
  allowBookShipment = false;
  userDefinedRefs = true;

  constructor(
    private alertService: AlertService,
    private ajax: AmplifyApiGWService,
    private auth: AuthenticationService,
    private data: DataService,
    private dataZip: ZipcodeService,
    private defaultData: DefaultDataService,
    private ltlEmail: LtlEmailService,
    private bolGenerator: BOLGeneratorService
  ) {
    this.currentLTLBooking = this.defaultData.setLTLBooking();

    this.subscriptions.push(
      this.auth.getUserDetails().subscribe((user: UserInfo) => {
        if (user) {
          this.currentLTLBooking.username = user.username;
          this.currentLTLBooking.useremail = user.email;
          this.currentLTLBooking.fullname = `${user.first_name} ${user.last_name}`;
          this.currentLTLBooking.companyname = user.company;
          this.currentLTLBooking.supportemail = user.support_email;
          this.currentLTLBooking.ccemails = user.email_cc_list;
          this.currentLTLBooking.pricing_program = user.pricing_program;
        } else {
          this.currentLTLBooking = this.defaultData.setLTLBooking();
        }

        this.ltlBookingObjSubject.next(this.currentLTLBooking);
      }),
      this.data.getParentCompanyInfo().subscribe((contact: custInfo) => {
        // If the contact is an {} as custInfo, set the billto as an empty object
        this.currentLTLBooking.billto = {
          companyname: contact.name || '',
          address: contact.address?.address || '',
          address2: contact.address?.address2 || '',
          city: contact.address?.city || '',
          state: contact.address?.state || '',
          postal_code: contact.address?.postal_code || '',
          country: 'US',
          isResidential: contact.address?.isResidential ? true : false,
        };
        this.currentLTLBooking.parent_cust_id = contact.cust_id?.toString() || '';
        this.ltlBookingObjSubject.next(this.currentLTLBooking);
      }),
      this.data.getCustomerInfo().subscribe((company: custInfo) => {
        console.log('Customer Info = ', company);
        this.currentLTLBooking.cust_id = company.cust_id?.toString() || '';
        this.currentLTLBooking.parent_cust_id = company.parent_cust_id?.toString() || '';
        this.ltlBookingObjSubject.next(this.currentLTLBooking);
      })
    );
  }

  currLTLBooking(): Observable<ltlBookingObj> {
    return this.ltlBookingObjSubject.asObservable();
  }

  getCurrLTLBooking(): ltlBookingObj {
    return this.currentLTLBooking;
  }

  readyToBook(): Observable<boolean> {
    return this.readyToBookSubject.asObservable();
  }

  setCurrLTLBooking(ltlBooking: ltlBookingObj): void {
    this.currentLTLBooking = JSON.parse(JSON.stringify(ltlBooking));
    this.ltlBookingObjSubject.next(ltlBooking);
  }

  updateBookingStatus(status: string): void {
    this.currentLTLBooking.booking_status = status;
    this.saveLTLBooking();
    this.ltlBookingObjSubject.next(this.currentLTLBooking);
  }

  updateLTLOrigin(origin: EntityInfo | contactObj): ltlBookingObj {
    if ('name' in origin) {
      this.currentLTLBooking.origin.companyname = origin?.name || '';
      this.currentLTLBooking.origin.address = origin?.address || '';
      this.currentLTLBooking.origin.address2 = origin?.address2 || '';
      this.currentLTLBooking.origin.city = origin?.city || '';
      this.currentLTLBooking.origin.state = origin?.state || '';
      this.currentLTLBooking.origin.postal_code = origin?.postal_code || '';
      this.currentLTLBooking.origin.country = origin?.country || '';
      this.currentLTLBooking.origin.isResidential =
        origin?.isResidential || false;
      this.ltlBookingObjSubject.next(this.currentLTLBooking);
    } else {
      this.currentLTLBooking.origin = JSON.parse(JSON.stringify(origin));
    }
    this.ltlBookingObjSubject.next(this.currentLTLBooking);
    return this.currentLTLBooking;
  }

  updateLTLDestination(destination: EntityInfo | contactObj): ltlBookingObj {
    if ('name' in destination) {
      this.currentLTLBooking.dest.companyname = destination?.name;
      this.currentLTLBooking.dest.address = destination?.address;
      this.currentLTLBooking.dest.address2 = destination?.address2;
      this.currentLTLBooking.dest.city = destination?.city;
      this.currentLTLBooking.dest.state = destination?.state;
      this.currentLTLBooking.dest.postal_code = destination?.postal_code;
      this.currentLTLBooking.dest.country = destination?.country;
      this.currentLTLBooking.dest.isResidential = destination?.isResidential;
      this.ltlBookingObjSubject.next(this.currentLTLBooking);
    } else {
      this.currentLTLBooking.dest = JSON.parse(JSON.stringify(destination));
    }
    this.ltlBookingObjSubject.next(this.currentLTLBooking);
    return this.currentLTLBooking;
  }

  setShipDate(shipDate: string): ltlBookingObj {
    this.currentLTLBooking.pickupDate = shipDate;
    if (
      this.currentLTLBooking.transitdays &&
      this.currentLTLBooking.transitdays > 0
    ) {
      this.currentLTLBooking.todate = this.calcDeliveryDate(
        shipDate,
        this.currentLTLBooking.transitdays
      );
    }
    this.ltlBookingObjSubject.next(this.currentLTLBooking);
    return this.currentLTLBooking;
  }

  updatePickupWindow(pickupTime: string, closeTime: string): ltlBookingObj {
    this.currentLTLBooking.pickuptime = pickupTime;
    this.currentLTLBooking.closetime = closeTime;
    this.ltlBookingObjSubject.next(this.currentLTLBooking);
    return this.currentLTLBooking;
  }

  calcDeliveryDate(shipDate: string, transitDays: number): string {
    const [year, month, day] = shipDate.split('-').map(Number);
    console.log(`month = ${month}, day = ${day}, year = ${year}`);
    const pickupDateObj = new Date(year, month - 1, day);
    console.log('pickupDateObj = ', pickupDateObj);
    pickupDateObj.setDate(pickupDateObj.getDate() + transitDays);
    console.log('pickupDateObj = ', pickupDateObj);
    return `${pickupDateObj.getFullYear()}-${(pickupDateObj.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${pickupDateObj
      .getDate()
      .toString()
      .padStart(2, '0')}`;
  }

  setInsInfo(insRequested: boolean, insValue: number): void {
    this.currentLTLBooking.insrequested = insRequested;
    this.currentLTLBooking.insValue = insValue;
    this.ltlBookingObjSubject.next(this.currentLTLBooking);
  }

  setServicesList(servicesList: string[]) {
    this.currentLTLBooking.accessorials = JSON.parse(
      JSON.stringify(servicesList)
    );
    this.ltlBookingObjSubject.next(this.currentLTLBooking);
  }

  calcItemDensity(item: objItem): number {
    if (
      !item.len ||
      !item.width ||
      !item.height ||
      !item.weight ||
      !item.numUnits
    ) {
      return 0;
    }
    const volume =
      (item.len * item.width * item.height * item.numUnits) /
      (item.unit === 'IN' ? 1728 : 1);
    const totalWeight = item.weight;
    return totalWeight / volume;
  }

  addObjItems(itemList: objItem[]): void {
    // For each item in the itemlist, create a new item object and add it to the currentLTLBooking.items array
    this.currentLTLBooking.items = itemList.map((item) => {
      return {
        unit: item.unit,
        description: '',
        hazmat: false,
        hazmatcode: '',
        nmfccode: '',
        len: item.len,
        width: item.width,
        height: item.height,
        handlingUnit: item.handlingUnit,
        numUnits: item.numUnits,
        weight: item.weight,
        pkgType: item.pkgType,
        numPkgs: item.numPkgs,
        class: item.class,
        density: this.calcItemDensity(item),
      };
    });

    // this.currentLTLBooking.items = JSON.parse(JSON.stringify(itemList));
    this.ltlBookingObjSubject.next(this.currentLTLBooking);
  }

  updateItemList(itemList: item[]): ltlBookingObj {
    this.currentLTLBooking.items = JSON.parse(JSON.stringify(itemList));
    this.ltlBookingObjSubject.next(this.currentLTLBooking);
    return this.currentLTLBooking;
  }

  updateOptionsList(options: any): ltlBookingObj {
    console.log('options = ', options);
    this.currentLTLBooking.reqPltLabels =
      options?.reqPltLabels ?? this.currentLTLBooking.reqPltLabels;
    this.currentLTLBooking.sendBOLtoShipper =
      options?.sendBOLtoShipper ?? this.currentLTLBooking.sendBOLtoShipper;
    this.currentLTLBooking.sendBOLtoConsignee =
      options?.sendBOLtoConsignee ?? this.currentLTLBooking.sendBOLtoConsignee;
    this.currentLTLBooking.billToReceiver =
      options?.billToReceiver ?? this.currentLTLBooking.billToReceiver;
    this.currentLTLBooking.receiverAcctNum =
      options?.receiverAcctNum ?? this.currentLTLBooking.receiverAcctNum;
    this.ltlBookingObjSubject.next(this.currentLTLBooking);
    return this.currentLTLBooking;
  }

  updateInstructions(instructions: any): ltlBookingObj {
    console.log('options = ', instructions);
    this.currentLTLBooking.specialinst =
      instructions?.specialinst ?? this.currentLTLBooking.specialinst;
    this.currentLTLBooking.pickupInst =
      instructions?.pickupInst ?? this.currentLTLBooking.pickupInst;
    this.currentLTLBooking.deliveryInst =
      instructions?.deliveryInst ?? this.currentLTLBooking.deliveryInst;
    this.ltlBookingObjSubject.next(this.currentLTLBooking);
    return this.currentLTLBooking;
  }

  updateReferenceList(refList: ShipReference[]): ltlBookingObj {
    const filteredRefList = refList.filter(
      (ref) => ref.refType && ref.refNumber
    );
    this.currentLTLBooking.references = JSON.parse(
      JSON.stringify(filteredRefList)
    );
    this.ltlBookingObjSubject.next(this.currentLTLBooking);
    return this.currentLTLBooking;
  }

  updateLTLQuoteInfo(quoteInfo: RateQuote): ltlBookingObj {
    this.currentLTLBooking.system = quoteInfo.System;
    this.currentLTLBooking.carriername = quoteInfo.CarrierName;
    this.currentLTLBooking.servicelevel = quoteInfo.ServiceLevel;
    this.currentLTLBooking.servicecode = quoteInfo.ServiceCodeLevel || '';
    this.currentLTLBooking.carrierscac = quoteInfo.CarrierScac;
    this.currentLTLBooking.transitdays =
      typeof quoteInfo.TransitDays === 'number'
        ? quoteInfo.TransitDays
        : parseInt(quoteInfo.TransitDays, 10);
    this.currentLTLBooking.quotenumber = quoteInfo.QuoteNumber;
    this.currentLTLBooking.cost = quoteInfo.Cost;
    this.currentLTLBooking.charge = quoteInfo.Charge;
    this.currentLTLBooking.chargewithinsurance = quoteInfo.ChargeWithInsurance;

    this.currentLTLBooking.todate = this.calcDeliveryDate(
      this.currentLTLBooking.pickupDate,
      this.currentLTLBooking.transitdays
    );

    const validSystem = (
      quoteInfo.System && this.validSystemTypes.includes(quoteInfo.System)
        ? quoteInfo.System
        : 'DEFAULT'
    ) as SystemType;
    const config = LTL_BOL_PAGE_CONFIG[validSystem];

    this.isOriginPhoneRequired = config.isOriginPhoneRequired;
    this.isDestPhoneRequired = config.isDestPhoneRequired;
    this.allowBookShipment = config.allowBookShipment;
    this.userDefinedRefs = config.userDefinedRefs;

    this.ltlBookingObjSubject.next(this.currentLTLBooking);
    return this.currentLTLBooking;
  }

  resetLTLQuoteInfo(): void {
    this.currentLTLBooking.system = '';
    this.currentLTLBooking.carriername = '';
    this.currentLTLBooking.servicelevel = '';
    this.currentLTLBooking.servicecode = '';
    this.currentLTLBooking.carrierscac = '';
    this.currentLTLBooking.transitdays = 0;
    this.currentLTLBooking.quotenumber = '';
    this.currentLTLBooking.cost = 0;
    this.currentLTLBooking.charge = 0;
    this.currentLTLBooking.chargewithinsurance = 0;
    this.currentLTLBooking.todate = this.currentLTLBooking.pickupDate;

    this.isOriginPhoneRequired = true;
    this.isDestPhoneRequired = true;
    this.allowBookShipment = false;
    this.userDefinedRefs = false;

    this.ltlBookingObjSubject.next(this.currentLTLBooking);
  }

  async validateInputs() {
    this.inputsValid = false;
    this.errorMessages = [];

    // Validate item descriptions, pickup window, and contact phones
    this.validateItems();
    this.validatePickupWindow();
    this.validateContactPhones();
    this.validateEmails();

    // If errors exist, display them and stop further execution
    if (await this.displayErrors()) {
      return;
    }

    // Validate addresses
    const shipperValid = await this.dataZip.validateAddress(
      this.currentLTLBooking.origin,
      'Shipper'
    );
    const consigneeValid = await this.dataZip.validateAddress(
      this.currentLTLBooking.dest,
      'Consignee'
    );

    if (!shipperValid || !consigneeValid) return;

    this.inputsValid = true;
  }

  // Helper functions
  private validateItems() {
    const invalidItems = this.currentLTLBooking.items.filter(
      (item) => !item.description
    );
    if (invalidItems.length > 0) {
      this.errorMessages.push('Item descriptions are required.');
    }
  }

  private validatePickupWindow() {
    if (
      !this.currentLTLBooking.pickuptime ||
      !this.currentLTLBooking.closetime
    ) {
      this.errorMessages.push('Pickup window is required.');
    }
  }

  private validateContactPhones() {
    if (this.isOriginPhoneRequired && !this.currentLTLBooking.origin.phone) {
      this.errorMessages.push('Shipper phone number is required.');
    }
    if (this.isDestPhoneRequired && !this.currentLTLBooking.dest.phone) {
      this.errorMessages.push('Consignee phone number is required.');
    }
  }

  private validateEmails() {
    this.checkEmail(
      this.currentLTLBooking.sendBOLtoShipper || false,
      this.currentLTLBooking.origin.email || '',
      'Shipper email address is required.'
    );
    this.checkEmail(
      this.currentLTLBooking.sendBOLtoConsignee || false,
      this.currentLTLBooking.dest.email || '',
      'Consignee email address is required.'
    );
  }

  private checkEmail(sendFlag: boolean, email: string, message: string) {
    if (sendFlag && !email) {
      this.errorMessages.push(message);
    }
  }

  private async displayErrors(): Promise<boolean> {
    if (this.errorMessages.length > 0) {
      await this.alertService.showAlert(this.errorMessages.join('\n'));
      return true;
    }
    return false;
  }

  async bookShipment() {
    await this.validateInputs(); // Wait for validateInputs to complete
    // console.log('Send RQ button clicked - validating addresses');

    if (!this.inputsValid) {
      console.log('Inputs are not valid.');
      return;
    }

    if (this.inputsValid) {
      if (this.allowBookShipment) {
        console.log('Book Shipment');
        // You can call your book shipment logic here
        this.bookLTLShpmnt(
          this.currentLTLBooking.useremail,
          this.currentLTLBooking.fullname,
          this.currentLTLBooking.system || '',
          this.currentLTLBooking.cust_id || '',
          this.currentLTLBooking.quotenumber,
          this.currentLTLBooking.pickupDate,
          this.currentLTLBooking.pickuptime || '',
          this.currentLTLBooking.closetime || '',
          this.currentLTLBooking.sendBOLtoShipper || false,
          this.currentLTLBooking.sendBOLtoConsignee || false,
          this.currentLTLBooking.references?.filter(
            (ref) => ref.refType && ref.refNumber
          ) || [], // Inline filter
          this.currentLTLBooking.specialinst || '',
          this.currentLTLBooking.origin,
          this.currentLTLBooking.dest
        );
      } else {
        // Save the booking object to the database
        console.log('Save Rate Quote. Time: ', new Date().toLocaleTimeString());
        this.currentLTLBooking.booking_status = 'EMAILED';
        await this.saveLTLBooking();
        this.ltlBookingObjSubject.next(this.currentLTLBooking);
        console.log('Send Rate Quote. Time: ', new Date().toLocaleTimeString());
        await this.sendPDFByEmail(this.currentLTLBooking); // If shipment cannot be booked, execute this function
        console.log('Rate Quote Sent. Time: ', new Date().toLocaleTimeString());
      }
    } else {
      console.log('Invalid inputs. Please fix the errors before proceeding.');
    }
  }

  async sendPDFByEmail(bolData: ltlBookingObj): Promise<void> {
    const pdfBytes = await this.bolGenerator.generatePDFData(bolData);

    // Convert the PDF byte data to base64 for sending in the email
    const base64PDF = await this.convertToBase64(pdfBytes);

    const emailRequest = {
      useremail: bolData.useremail,
      supportemail: bolData.supportemail,
      ccemails: bolData.ccemails,
      emailSubject: 'BOL Document',
      emailBody: this.ltlEmail.createLTLShpmntEmailString(
        this.currentLTLBooking,
        this.userDefinedRefs
      ),
      attachment: {
        filename: `DRAFT BOL - ${bolData.companyname} - quote number ${bolData.quotenumber}.pdf`,
        content: base64PDF,
        contentType: 'application/pdf',
      },
    };

    try {
      const path = '/catalogs/sendemail';

      const result = await this.ajax.callApi(path, 'POST', {}, emailRequest);

      this.alertService.showAlert(
        'Booking details have been sent to your support team and to your email address.'
      );
    } catch (error: any) {
      console.error(error);
      this.alertService.showAlert(
        'Error sending email - please try again later.'
      );
    }
  }

  private async convertToBase64(pdfBytes: Uint8Array): Promise<string> {
    return new Promise((resolve) => {
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result?.toString().split(',')[1] || '';
        resolve(base64String);
      };
      reader.readAsDataURL(blob);
    });
  }

  async saveLTLBooking(): Promise<any> {
    // Save the booking to the database
    console.log('Saving booking object: ', this.currentLTLBooking);

    try {
      const apiBody = this.currentLTLBooking;

      const path = '/save_ltl_booking';

      const result = await this.ajax.callApi(path, 'POST', {}, apiBody);

      this.currentLTLBooking.booking_key = result.booking_key;
      this.currentLTLBooking.origin.ent_key = result.ent_origin;
      this.currentLTLBooking.dest.ent_key = result.ent_dest;

      this.ltlBookingObjSubject.next(this.currentLTLBooking);

      return result;
    } catch (error) {
      await this.alertService.showAlert(
        'There was an error saving the booking object. Please try again later.'
      );
      console.error(error);
      return -1;
    }
  }

  async saveRateQuote(data: ltlBookingObj): Promise<void> {
    // Save the rate quote to the database
    console.log('Constructing Save Rate Quote email: ', data);

    let currentDate = new Date().toLocaleDateString();
    let emailSubject = `Rate Quote Request: ${data.origin.postal_code} to ${data.dest.postal_code}. Date: ${currentDate}`;
    let emailBody = this.ltlEmail.createSaveRQEmailString(data);

    try {
      const apiBody = {
        useremail: data.useremail,
        supportemail: data.supportemail,
        ccemails: [],
        emailSubject: emailSubject,
        emailBody: emailBody,
      };

      const path = '/catalogs/sendemail';

      const result = await this.ajax.callApi(path, 'POST', {}, apiBody);

      // pop up an alert that the email was sent successfully
      await this.alertService.showAlert(
        'Rate quote details have been sent to your email address.'
      );
    } catch (error) {
      await this.alertService.showAlert(
        'There was an error sending the email. Please try again later.'
      );
      console.error(error);
    }
  }

  async sendRateQuote() {
    console.log('bookingObj = ', this.currLTLBooking);
    let emailSubject = `LTL Booking Request Confirmation For: ${this.currentLTLBooking.fullname}`;
    let emailBody = this.ltlEmail.createLTLShpmntEmailString(
      this.currentLTLBooking,
      this.userDefinedRefs
    );
    console.log('emailBody = ', emailBody);

    try {
      const apiBody = {
        useremail: this.currentLTLBooking.useremail,
        supportemail: this.currentLTLBooking.supportemail,
        ccemails: this.currentLTLBooking.ccemails,
        emailSubject: emailSubject,
        emailBody: emailBody,
      };

      const path = '/catalogs/sendemail';

      const result = await this.ajax.callApi(path, 'POST', {}, apiBody);

      this.alertService.showAlert(
        'Booking details have been sent to your support team and to your email address.'
      );
    } catch (error) {
      console.error(error);
      this.alertService.showAlert(
        'Error sending email - please try again later.'
      );
    }
  }

  bookLTLShpmnt(
    user_email: string,
    full_name: string,
    booking_system: string,
    cust_id: string,
    quote_id: string,
    shipDate: string,
    readyTime: string,
    closeTime: string,
    emailBOLtoSender: boolean,
    emailBOLtoReceiver: boolean,
    shipRefs: ShipReference[],
    specialInstructions: string,
    senderInfo: contactObj,
    receiverInfo: contactObj
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      //this.saveAddress();
      // this.errorMessage = '';
      try {
        let sbRequest = {
          user_email: user_email,
          full_name: full_name,
          booking_system: booking_system,
          cust_id: cust_id,
          shipment_quote_id: quote_id,
          shipment_date: shipDate,
          shipment_ready_time: readyTime,
          shipment_closing_time: closeTime,
          email_BOL_to_sender: emailBOLtoSender ? 'Y' : 'N',
          email_BOL_to_receiver: emailBOLtoReceiver ? 'Y' : 'N',
          booking_refs: [] as BookingRef[],
          special_instructions: specialInstructions,
          sender_entity: {
            name: senderInfo.companyname,
            address: senderInfo.address,
            address_2: senderInfo.address2,
            city: senderInfo.city,
            state: senderInfo.state,
            postal_code: senderInfo.postal_code,
            country: senderInfo.country,
            contact_name: senderInfo.contactname,
            contact_phone: senderInfo.phone,
            contact_email: senderInfo.email,
            residential: senderInfo.isResidential,
          },
          receiver_entity: {
            name: receiverInfo.companyname,
            address: receiverInfo.address,
            address_2: receiverInfo.address2,
            city: receiverInfo.city,
            state: receiverInfo.state,
            postal_code: receiverInfo.postal_code,
            country: receiverInfo.country,
            contact_name: receiverInfo.contactname,
            contact_phone: receiverInfo.phone,
            contact_email: receiverInfo.email,
            residential: receiverInfo.isResidential,
          },
        };
        if (shipRefs && shipRefs.length > 0) {
          shipRefs.forEach((ref: ShipReference) => {
            sbRequest.booking_refs.push({
              ref_type: ref.refType,
              ref_num: ref.refNumber,
              num_packages: ref.numPackages,
              description: ref.refDescription,
            });
          });
        }
        console.log('sbRequest = ', sbRequest);
        this.sendBookShipmentRequest(sbRequest)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            console.error('Error: ', error);
            reject(error);
          });
      } catch (e) {
        reject(e); // Reject the promise if an exception is thrown
      }
    });
  }

  // Function to make the API call
  sendBookShipmentRequest(bookShpReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ajax
        .callApi('/book_shipment', 'POST', {}, bookShpReq)
        .then((result) => {
          console.log('Result: ', result);
          if (result.hasOwnProperty('error')) {
            console.error('Error Response: ', result);
            reject(result);
            return;
          }
          // result = JSON.parse(result);
          resolve(result);
        })
        .catch((error) => {
          console.error('Error, try again', error);
          reject(error);
        });
    });
  }
}
