import { Component, OnInit, OnDestroy, Inject, Input } from '@angular/core';
import { DefaultDataService } from '../../services/default-data.service';
import { AuthenticationService } from '../../services/authentication.service';
import {
  contactObj,
  custInfo,
  objItem,
  item,
  ltlBookingObj,
} from '../../interfaces';
import {
  Subscription,
  forkJoin,
  catchError,
  from,
  of,
} from 'rxjs';
import { AmplifyApiGWService } from '../../services/amplify-apigw.service';
import { RateQuoteService } from '../../services/rate-quote.service';
import { ZipcodeService } from '../../services/zipcode/zipcode.service';
import { DataService } from '../../services/data.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-send-ftl-modal',
  templateUrl: './send-ftl-modal.component.html',
  styleUrls: ['./send-ftl-modal.component.scss'],
})
export class SendFTLModalComponent implements OnInit, OnDestroy {
  @Input() requestType!: string;
  @Input() shipper!: contactObj;
  @Input() consignee!: contactObj;
  @Input() billTo: contactObj = {} as contactObj;
  @Input() insRequested!: boolean;
  @Input() insValue!: number;
  @Input() shipDate!: string;
  @Input() serviceItems!: string[];
  @Input() currentItems!: objItem[];
  @Input() showBillTo!: boolean;

  modalTitle: string = 'Complete FTL Shipment Request';
  shipperObj: contactObj = {} as contactObj;
  consigneeObj: contactObj = {} as contactObj;
  billToObj: contactObj = {} as contactObj;
  shipTime: string = '10:00';
  closeTime: string = '17:00';
  serviceItemString: string = '';
  serviceItemsList: any[] = [];
  selectedServiceItems: string[] = [];
  specialInstructions: string = '';
  itemList: item[] = [];
  ftlEmailObj: ltlBookingObj = {} as ltlBookingObj;
  subscriptions: Subscription[] = [];
  username: string = '';
  useremail: string = '';
  fullname: string = '';
  usercompanies: string[] = [];
  custIDString: string = '';
  supportEmail: string = '';
  cc_email_list: string[] = [];

  constructor(
    private modalController: ModalController,
    private defaultData: DefaultDataService,
    private dataZip: ZipcodeService,
    private auth: AuthenticationService,
    private ajax: AmplifyApiGWService,
    private dataService: DataService,
    private rqService: RateQuoteService
  ) {}

  ngOnInit() {
    this.requestType = this.requestType;
    this.modalTitle = 'Complete FTL Shipment Request';
  
    // Deep clone to ensure immutability
    this.shipperObj = JSON.parse(JSON.stringify(this.shipper));
    this.consigneeObj = JSON.parse(JSON.stringify(this.consignee));
    this.billToObj = JSON.parse(JSON.stringify(this.billTo));
    this.insValue = this.insValue || 0;
    
    // Cloning service items to avoid mutations
    this.serviceItems = JSON.parse(JSON.stringify(this.serviceItems));
    // console.log('Service Items: ', this.serviceItems);
    this.serviceItemsList = JSON.parse(JSON.stringify(this.defaultData.getServiceItems()));
    // console.log('Service Items List: ', this.serviceItemsList);

    // Mapping selected service items
    this.selectedServiceItems = this.serviceItems.map((item) => {
      const foundItem = this.serviceItemsList.find(
        (serviceItem) => serviceItem.value === item
      );
      return foundItem ? foundItem.label : null;
    }).filter(label => label !== null); // Ensure only valid labels
    // console.log('Selected Service Items: ', this.selectedServiceItems);

    // Create a string from the currentItems list where the labels are separated by commas
    this.serviceItemString = this.selectedServiceItems.join(', ');
  
    // Cloning current items
    this.currentItems = JSON.parse(JSON.stringify(this.currentItems));
    this.showBillTo = this.showBillTo;
    
    // Convert the currentItems list to an itemList
    this.itemList = this.currentItems.map((item) => {
      return {
        unit: item.unit,
        description: '',
        hazmat: false,
        hazmatcode: '',
        nmfccode: '',
        len: item.len,
        width: item.width,
        height: item.height,
        handlingUnit: item.handlingUnit || '',
        numUnits: item.numUnits,
        weight: item.weight,
        pkgType: item.pkgType || '',
        numPkgs: item.numPkgs,
        class: item.class,
        // The density is the weight divided by the amount times the len times the width times the height. If the unit is 'IN', the len, width, and height must all be multiplied by 12.
        density:
          item.weight === null ||
          item.numUnits === null ||
          item.len === null ||
          item.width === null ||
          item.height === null
            ? -1
            : (item.weight * (item.unit === 'IN' ? 1728 : 1)) /
              (item.numUnits * item.len * item.width * item.height),
      };
    });
  
    // Subscriptions
    this.subscriptions.push(
      this.auth.getUserDetails().subscribe((userDetails) => {
        this.username = userDetails.username;
        this.useremail = userDetails.email;
        this.cc_email_list = userDetails.email_cc_list;
        this.fullname = userDetails.full_name;
        this.usercompanies = userDetails.companyList;
      }),
      this.dataService.getCustomerInfo().subscribe((customerInfo: custInfo) => {
        this.custIDString = customerInfo.cust_id.toString();
      }),
      this.dataService.supportEmail$.subscribe((supportEmail) => {
        this.supportEmail = supportEmail;
      })
    );
  
    // Debugging logs
    // console.log('Request Type: ', this.requestType);
    // console.log('Ship Date: ', this.shipDate);
    // console.log('Service Items: ', this.serviceItems);
    // console.log('Current Items: ', this.currentItems);
    // console.log('Selected Service Items: ', this.selectedServiceItems);
    // console.log('Service Item String: ', this.serviceItemString);
  } 

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getCurrencyString(value: number): string {
    // Remove $ and , from the string
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });
  }

  handleShipperObjChangeEvent(event: contactObj) {
    this.shipperObj = JSON.parse(JSON.stringify(event));
  }

  handleConsigneeObjChangeEvent(event: contactObj) {
    this.consigneeObj = JSON.parse(JSON.stringify(event));
  }

  handleBillToObjChangeEvent(event: contactObj) {
    this.billToObj = JSON.parse(JSON.stringify(event));
  }

  // openDraftBOL() {
  //   console.log('Download Draft BOL');
  //   this.bolSvc.openPdf(
  //     this.shipperObj,
  //     this.consigneeObj,
  //     this.billToObj,
  //     this.itemList,
  //     this.shipDate,
  //     '',
  //     this.specialInstructions,
  //     '', // carrierPhone
  //     '', // BOLNumber
  //     '', // proNumber
  //     '', // aljexNum
  //     '', // trailerNum
  //     '' // sealNum
  //   );
  // }

  createAddressString(label: string, address: contactObj): string {
    let addressText = `${address.companyname ? `
    Company:                ${address.companyname}` : ''}${address.contactname ? `
    Contact:                ${address.contactname}` : ''}${address.address ? `
    Address:                ${address.address}` : ''}${address.postal_code ? `
                            ${address.city ? `${address.city}, ` : ''}${address.state ? `${address.state} ` : ''}${address.postal_code}${address.country ? `  ${address.country}` : '  USA'}` : ''}`;
    
    addressText += `${address.phone ? `
    Phone:                  ${address.phone}` : ''}${address.email ? `
    Email:                  ${address.email}` : ''}`;

    if (addressText.length > 0) {
      return `

${label}:${addressText}`;
    } else {
      return '';
    }
  }

  // Function to create the email body string
  createEmailString(data: any): string {
    let emailBody =
      '<pre width="300" style="font-size: 14px; max-width: 100%;">';

    emailBody += `Requester:
    Name:                   ${data.fullname || data.username}
    Company:                ${data.companyname}
    Email:                  ${data.useremail}`;

    emailBody += this.createAddressString('Ship From', data.origin);
    emailBody += this.createAddressString('Ship To', data.dest);
    emailBody += this.createAddressString('Bill To', data.billto);

    // if data.accessorials[0].type.length > 1, then set shipDetails to 'Multiple'
    let shipDetails = `${data.accessorials[0].type.length > 0 ? `
    Accessorials:           ${data.accessorials[0].type.join(', ')}` : ''}${this.requestType === 'FTL' ? `
    Insurance Requested?    ${this.insRequested ? 'Yes' : 'No'}` : ''}${data.insValue ? `
    Shipment Value:         ${this.getCurrencyString(data.insValue)}` : ''}${data.specialinst ? `
    Special Instructions:   ${data.specialinst}` : ''}`;

    emailBody += `${shipDetails ? `

Shipment Details:${shipDetails}` : ''}`;

    data.items.forEach((item: item, index: number) => {
      emailBody += `

Line Item ${index + 1}:
    Description:            ${item.description}
    HazMat:                 ${item.hazmat ? 'Yes' : 'No'}
    HazMat Code:            ${item.hazmatcode}
    Class:                  ${item.class}
    NMFC:                   ${item.nmfccode}
    Weight:                 ${item.weight}
    Handling Unit:          ${item.handlingUnit}
    Length:                 ${item.len}
    Width:                  ${item.width}
    Height:                 ${item.height}
    Number of Units:        ${item.numUnits}
    Package Type:           ${item.pkgType}
    Number of Packages:     ${item.numPkgs}
    Density:                ${item.density}
    Unit:                   ${item.unit}`;
    });

    emailBody += '</pre>';
    return emailBody;
  }

  async sendFTLRateQuoteRequest() {
    // console.log('Send RQ button clicked - validating addresses');
  
    // Validate the shipper, consignee, and billto addresses
    forkJoin({
      shipper: this.dataZip.validateAndSaveAddress(
        this.shipperObj,
        this.custIDString,
        ''
      ),
      consignee: this.dataZip.validateAndSaveAddress(
        this.consigneeObj,
        this.custIDString,
        ''
      ),
      billTo: this.dataZip.validateAndSaveAddress(
        this.billToObj,
        this.custIDString,
        ''
      ),
    }).subscribe(results => {
      // console.log('Validation results = ', results);
      // Update the shipper, consignee, and billto objects with the validated addresses
      this.shipperObj = results.shipper.address;
      this.consigneeObj = results.consignee.address;
      this.billToObj = results.billTo.address;
  
      // Send the rate quote email
  
      this.ftlEmailObj = {
        username: this.username,
        useremail: this.useremail,
        fullname: this.fullname,
        companyname: this.usercompanies[0],
        supportemail: this.supportEmail,
        insrequested: this.insRequested,
        insValue: this.insValue,
        pickupDate: this.shipDate,
        pickuptime: this.shipTime,
        closetime: this.closeTime,
        todate: this.shipDate,
        origin: this.shipperObj,
        dest: this.consigneeObj,
        billto: this.billToObj,
        items: this.itemList,
        accessorials: this.selectedServiceItems,
        specialinst: this.specialInstructions,
        system: '',
        carriername: '',
        servicelevel: '',
        servicecode: 'FTL',
        carrierscac: '',
        transitdays: 1,
        cost: 0.00,
        charge: 0.00,
        chargewithinsurance: 0.00,
        quotenumber: '',
      };
      // console.log('ftlEmailObj = ', this.ftlEmailObj);
      let emailSubject = `Volume/FTL Shipment Request from: ${this.ftlEmailObj.fullname}`;
      let emailBody = this.createEmailString(this.ftlEmailObj);

      const apiBody = JSON.stringify({
        useremail: this.useremail,
        supportemail: this.supportEmail,
        ccemails: this.cc_email_list,
        emailSubject: emailSubject,
        emailBody: emailBody,
      });

      const path = '/catalogs/sendemail';

      forkJoin(
        from(this.rqService.loadFTLRequest(
          this.shipperObj,
          this.consigneeObj,
          this.shipDate,
          this.serviceItems,
          this.insValue,
          this.insRequested,
          this.currentItems,
          this.specialInstructions
        )).pipe(catchError(error => {
          console.error('Error loading FTL request', error);
          return of(null); // handle error and return null
        })),
        from(this.ajax.callApi(
            path,
            'POST',
            {},
            apiBody
        )).pipe(catchError(error => {
          console.error('Error sending request email', error);
          return of(null); // handle error and return null
        }))
      ).subscribe(([loadFTLResult, sendEmailResult]) => {
        if (sendEmailResult) {
          this.modalController.dismiss('Success sending email');
        } else {
          this.modalController.dismiss('Error sending email');
        }
      });
    });
  }
  

  closeModal() {
    this.modalController.dismiss('Close button clicked');
  }
}
