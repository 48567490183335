import { Injectable } from '@angular/core';
import {
  PDFDocument,
  PDFPage,
  PDFFont,
  rgb,
  StandardFonts,
  lineSplit,
  degrees,
} from 'pdf-lib';
import { ltlBookingObj, contactObj } from '../interfaces';
import bolText from '../../assets/txt/bol-text.json';
import { create } from 'domain';
import { min } from 'rxjs';

const PAGE_WIDTH = 600;
const PAGE_HEIGHT = 800;
const SIDE_MARGIN = 30;
const TOP_MARGIN = 30;
const TITLE_FONT_SIZE = 14;
const SMALL_FONT_SIZE = 10;
const TINY_FONT_SIZE = 9;
const X_TINY_FONT_SIZE = 7;
const LINE_FACTOR = 1.2;
const LINE_HEIGHT = SMALL_FONT_SIZE * LINE_FACTOR; // Line spacing
const AFTER_TEXT_PADDING = 3;
const LOGO_WIDTH = 150;
const BOXSPACING = 5;
const BOXPADDING = 3;
const BOX_COL_PADDING = 2;
const BOX_BORDER_WIDTH = 0;
const LOGO_PATH = './assets/img/freightdesk_logo.png';
const BOL_TEXT_PATH = './assets/img/bol_text.png';

const defaultContactObj: contactObj = {
  companyname: '',
  address: '',
  city: '',
  state: '',
  postal_code: '',
  country: '',
  isResidential: false,
  contactname: '',
  phone: '',
  email: '',
};

// NOTE: Total box height in the 'drawRectangle' method will be calculated as: height + borderWidth.
// ALSO, the y-coordinate should be the bottom of the box PLUS half the borderWidth.
// ALSO, the y-coordinate for the text will be the base of the text, but decenders (e.g., lower case g) will hang below that.

@Injectable({
  providedIn: 'root',
})
export class BOLGeneratorService {
  constructor() {}

  async generatePDFData(bolData: ltlBookingObj): Promise<Uint8Array> {
    // This method returns the raw PDF byte data
    const pdfBytes = await this.generateBillOfLading(bolData);
    return pdfBytes;
  }

  async generateBillOfLading(data: ltlBookingObj) {
    const pdfDoc = await PDFDocument.create();
    const pdfForm = pdfDoc.getForm();
    const page = pdfDoc.addPage([PAGE_WIDTH, PAGE_HEIGHT]); // Standard letter size
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

    let yCursor = PAGE_HEIGHT - TOP_MARGIN; // Start at the top of the page

    // Render the title section
    yCursor -= await this.drawTitleSection(
      pdfDoc,
      page,
      data,
      boldFont,
      font,
      yCursor
    );

    yCursor -= await this.drawBillingAndOrderNotesSection(
      page,
      data,
      boldFont,
      font,
      yCursor
    );

    yCursor -= await this.drawStopsSection(page, data, boldFont, font, yCursor);

    yCursor -= await this.drawFreightDetailsSection(
      page,
      data,
      boldFont,
      font,
      yCursor
    );

    yCursor -= await this.drawTermsTextSection(page, font, yCursor);

    // page.drawText('End of Form', {
    //   x: SIDE_MARGIN,
    //   y: yCursor - SMALL_FONT_SIZE,
    //   font: font,
    //   size: SMALL_FONT_SIZE,
    // });

    return await pdfDoc.save();
  }

  // SECTION RENDERING METHODS

  private async drawTitleSection(
    pdfDoc: PDFDocument,
    page: PDFPage,
    data: ltlBookingObj,
    boldFont: PDFFont,
    font: PDFFont,
    yCursor: number
  ): Promise<number> {
    // Left-aligned "Bill of Lading"
    // console.log('yCursor:', yCursor - 1.2 * TITLE_FONT_SIZE);
    page.drawText('Bill of Lading', {
      x: SIDE_MARGIN,
      y: yCursor - TITLE_FONT_SIZE,
      font: boldFont,
      size: TITLE_FONT_SIZE,
    });

    // Centered logo
    const logoBytes = await fetch(LOGO_PATH).then((res) => res.arrayBuffer());
    const logoImage = await pdfDoc.embedPng(logoBytes);
    const logoHeight = (LOGO_WIDTH * logoImage.height) / logoImage.width;
    const logoX = (PAGE_WIDTH - LOGO_WIDTH) / 2;
    const logoY = yCursor - (TITLE_FONT_SIZE + logoHeight) / 2;
    page.drawImage(logoImage, {
      x: logoX,
      y: logoY,
      width: LOGO_WIDTH,
      height: logoHeight,
    });

    // Right-aligned "Order #:"
    const orderLabel = 'BOL # ';
    const orderValue = `${data.rqkey || 'XXX'}`;

    // Measure text width to align correctly
    const orderLabelWidth = font.widthOfTextAtSize(orderLabel, SMALL_FONT_SIZE);
    const orderValueWidth = boldFont.widthOfTextAtSize(
      orderValue,
      SMALL_FONT_SIZE
    );
    const totalWidth = orderLabelWidth + orderValueWidth;

    // Draw "Order #:" in normal font
    page.drawText(orderLabel, {
      x: PAGE_WIDTH - SIDE_MARGIN - totalWidth,
      y: yCursor - (TITLE_FONT_SIZE + SMALL_FONT_SIZE) / 2,
      font: font, // Normal font
      size: SMALL_FONT_SIZE,
    });

    // Draw the rqkey in bold font right after "Order #:"
    page.drawText(orderValue, {
      x: PAGE_WIDTH - SIDE_MARGIN - orderValueWidth,
      y: yCursor - (TITLE_FONT_SIZE + SMALL_FONT_SIZE) / 2,
      font: boldFont, // Bold font
      size: SMALL_FONT_SIZE,
    });

    // Adjust yCursor below the logo
    // console.log('yCursor:', yCursor - 1.2 * TITLE_FONT_SIZE);
    // console.log('logoY:', logoY);
    return yCursor - Math.min(yCursor - 1.2 * TITLE_FONT_SIZE, logoY);
  }

  private async drawBillingAndOrderNotesSection(
    page: PDFPage,
    data: ltlBookingObj,
    boldFont: PDFFont,
    font: PDFFont,
    yCursor: number
  ): Promise<number> {
    const leftColumnWidth =
      (PAGE_WIDTH - 2 * SIDE_MARGIN - BOXSPACING) * (2 / 3); // Left 2/3 of the page
    const rightColumnX = SIDE_MARGIN + leftColumnWidth + BOXSPACING;

    let currentY = yCursor;

    // 1. Left Column: Freight Charges Bill To
    page.drawText('Freight charges bill to 3rd party', {
      x: SIDE_MARGIN,
      y: currentY - SMALL_FONT_SIZE,
      font: boldFont,
      size: SMALL_FONT_SIZE,
    });
    currentY -= LINE_HEIGHT + AFTER_TEXT_PADDING;

    // Handle Freight Charges info
    let companyContactLines = this.createContactText(
      data.billto ? data.billto : defaultContactObj,
      ''
    );

    currentY -=
      (await this.createStructuredBox(
        page,
        SIDE_MARGIN,
        currentY,
        leftColumnWidth,
        2,
        [...companyContactLines]
      )) + AFTER_TEXT_PADDING;

    currentY -=
      (await this.createStructuredBox(
        page,
        SIDE_MARGIN,
        currentY,
        leftColumnWidth,
        1,
        [[`Carrier Name: ${data.carriername}`]],
        [],
        '',
        '',
        '',
        0.5,
        SMALL_FONT_SIZE,
        false
      )) + AFTER_TEXT_PADDING;

    currentY -=
      (await this.drawReferencesSection(
        page,
        data,
        boldFont,
        font,
        leftColumnWidth,
        currentY
      )) + AFTER_TEXT_PADDING;

    const leftColumnY = currentY;

    // 2. Right Column: Order Notes
    currentY = yCursor;
    page.drawText('Order notes', {
      x: rightColumnX,
      y: currentY - SMALL_FONT_SIZE,
      font: boldFont,
      size: SMALL_FONT_SIZE,
    });
    currentY -= LINE_HEIGHT + AFTER_TEXT_PADDING;

    // Draw the right-column box
    const orderNotesWidth = PAGE_WIDTH - rightColumnX - SIDE_MARGIN;
    const notesPlaceholder =
      data.specialinst ||
      'This space is for any special instructions required for the shipment.';

    const rightColumnHeight = await this.createStructuredBox(
      page,
      rightColumnX,
      currentY,
      orderNotesWidth,
      1,
      [[notesPlaceholder]]
    );

    let rightColumnY = currentY - rightColumnHeight - AFTER_TEXT_PADDING;

    // Update yCursor to the lowest point of the two sections
    return yCursor - Math.min(leftColumnY, rightColumnY);
  }

  private async drawReferencesSection(
    page: PDFPage,
    data: ltlBookingObj,
    boldFont: PDFFont,
    font: PDFFont,
    sectionWidth: number,
    yCursor: number
  ): Promise<number> {
    let currentY = yCursor;
    // References
    currentY = yCursor;
    page.drawText('References', {
      x: SIDE_MARGIN,
      y: currentY - SMALL_FONT_SIZE,
      font: boldFont,
      size: SMALL_FONT_SIZE,
    });
    currentY -= LINE_HEIGHT + AFTER_TEXT_PADDING;

    // Draw the right-column box

    // Create a list of reference text using data.references with "RefType: RefNumber" format
    const referenceLines = data.references?.map(
      (ref) => `${ref.refType}: ${ref.refNumber}`
    );

    // Divide referenceLines into 3 lists of references as evenly devided as possible
    const numCols = referenceLines ? 2 : 1;
    const numLines = referenceLines
      ? Math.ceil(referenceLines.length / numCols)
      : 0;
    const referenceLinesCols = Array.from({ length: numCols }, (_, i) =>
      referenceLines
        ? referenceLines.slice(i * numLines, (i + 1) * numLines)
        : []
    );

    console.log('referenceLinesCols:', referenceLinesCols);

    const rightColumnHeight = await this.createStructuredBox(
      page,
      SIDE_MARGIN,
      currentY,
      sectionWidth,
      2,
      [
        referenceLines && referenceLines.length > 0
          ? referenceLinesCols[0]
          : ['None provided'],
        referenceLinesCols.length > 1 ? referenceLinesCols[1] : [],
      ]
    );

    // Update yCursor to the lowest point of the two sections
    return rightColumnHeight + LINE_HEIGHT + 2 * AFTER_TEXT_PADDING;
  }

  private async drawStopsSection(
    page: PDFPage,
    data: ltlBookingObj,
    boldFont: PDFFont,
    font: PDFFont,
    yCursor: number
  ): Promise<number> {
    let currentY = yCursor;

    // Handle Freight Charges info
    const pickupLines = this.createContactText(data.origin, '');

    const formattedPickupDate = data.pickupDate
      ? data.pickupDate
      : 'mm/dd/yyyy';
    const formattedPickupTime = data.pickuptime ? data.pickuptime : 'HH:MM';
    const formattedCloseTime = data.closetime ? data.closetime : 'HH:MM';

    const pickupWindowLine = `${formattedPickupDate} ${formattedPickupTime} - ${formattedCloseTime}`;

    let deliveryDate = 'mm/dd/yyyy';
    if (data.todate) {
      deliveryDate = data.todate;
    }

    let originBoxHeight = await this.createStructuredBox(
      page,
      SIDE_MARGIN,
      currentY,
      PAGE_WIDTH - 2 * SIDE_MARGIN,
      3,
      [...pickupLines, [pickupWindowLine]],
      [40, 30, 30],
      'ORIGIN',
      `Pickup notes: ${data.pickupInst || 'None'}`,
      undefined,
      undefined,
      undefined,
      true
    );

    currentY -= originBoxHeight;

    const deliveryLines = this.createContactText(data.dest, '');

    let deliveryBoxHeight = await this.createStructuredBox(
      page,
      SIDE_MARGIN,
      currentY,
      PAGE_WIDTH - 2 * SIDE_MARGIN,
      3,
      [...deliveryLines, [deliveryDate]],
      [40, 30, 30],
      'DEST',
      `Delivery notes: ${data.deliveryInst || 'None'}`,
      undefined,
      undefined,
      undefined,
      true
    );

    currentY -= deliveryBoxHeight + AFTER_TEXT_PADDING;

    return yCursor - currentY;
  }

  // Draw the Freight Details section
  private async drawFreightDetailsSection(
    page: PDFPage,
    data: ltlBookingObj,
    boldFont: PDFFont,
    font: PDFFont,
    yCursor: number
  ): Promise<number> {
    let currentY = yCursor;
    // Calculate the total number of handling units and weight
    let totalNumUnits = 0;
    let totalWeight = 0;

    if (data.items && Array.isArray(data.items)) {
      totalNumUnits = data.items.reduce(
        (sum, item) => sum + (item.numUnits || 0),
        0
      );
      totalWeight = data.items.reduce(
        (sum, item) => sum + (item.weight || 0),
        0
      );
    }

    // Format the weight as a number with commas and two decimal places
    const formattedWeight = totalWeight.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Prepare text for each column
    const firstColumnText = ['SHIPMENT INFORMATION'];
    const secondColumnText = [
      `Handling Units: ${
        data.items && data.items.length > 0
          ? data.items[0].handlingUnit || 'N/A'
          : 'N/A'
      } (${totalNumUnits})`,
    ];
    const thirdColumnText = [`Total Weight: ${formattedWeight} lbs`];

    // Call createStructuredBox to draw the box
    const freightDetailsBoxHeight = await this.createStructuredBox(
      page,
      SIDE_MARGIN,
      currentY,
      PAGE_WIDTH - 2 * SIDE_MARGIN,
      3,
      [firstColumnText, secondColumnText, thirdColumnText],
      [40, 30, 30], // Column widths as percentages
      '', // No label
      '', // No bottom text
      '', // No top text
      0 // No border
    );

    // Update yCursor
    currentY -= freightDetailsBoxHeight;

    let freightColumnWidths = [20, 9, 5, 16, 12, 7, 7, 5, 7, 7, 5];

    // Column headers for the shaded box
    const shadedBoxHeaders = [
      ['DESCRIPTION'],
      ['HU'],
      ['# UNITS'],
      ['DIMENSIONS'],
      ['WEIGHT'],
      ['CLASS'],
      ['NMFC'],
      ['HM'],
      ['HM CODE'],
      ['PCE TYPE'],
      ['# PCS'],
    ];

    // Call createStructuredBox for the shaded box
    const shadedBoxHeight = await this.createStructuredBox(
      page,
      SIDE_MARGIN,
      currentY,
      PAGE_WIDTH - 2 * SIDE_MARGIN,
      11, // Number of columns
      [...shadedBoxHeaders], // Header text
      freightColumnWidths, // Column widths in percentages
      '', // No label
      '', // No bottom text
      '', // No top text
      0.5, // Border width
      X_TINY_FONT_SIZE, // Font size
      true, // Shaded
      true // Centered
    );

    // Update yCursor
    currentY -= shadedBoxHeight;

    if (data.items && Array.isArray(data.items) && data.items.length > 0) {
      // Create a box for each item in data.items
      for (const item of data.items) {
        const itemDimensions = `${item.len || 0}x${item.width || 0}x${
          item.height || 0
        }`;
        const itemWeight = (item.weight || 0).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        // console.log('item:', item);
        // Item data for the respective columns
        const itemData = [
          [item.description || 'N/A'], // DESCRIPTION
          [item.handlingUnit || 'N/A'], // HANDLING UNIT
          [`${item.numUnits || 0}`], // UNIT #
          [itemDimensions], // DIMENSIONS
          [`${itemWeight} lbs`], // WEIGHT
          [`${item.class}` || 'N/A'], // CLASS
          [`${item.nmfccode || 'N/A'}`], // NMFC
          [item.hazmat ? 'X' : ''], // HM
          [item.hazmatcode || 'N/A'], // HM CODE
          [item.pkgType || 'N/A'], // PIECE TYPE
          [`${item.numPkgs || 0}`], // PIECE #
        ];
        // console.log('itemData:', itemData);

        // Create the unshaded box for the current item
        const itemBoxHeight = await this.createStructuredBox(
          page,
          SIDE_MARGIN,
          currentY,
          PAGE_WIDTH - 2 * SIDE_MARGIN,
          11, // Number of columns
          itemData, // Item data
          freightColumnWidths, // Column widths in percentages
          '', // No label
          '', // No bottom text
          '', // No top text
          0.5, // Border width
          X_TINY_FONT_SIZE, // Font size
          false, // Not shaded
          true // Centered
        );

        // Update yCursor
        currentY -= itemBoxHeight;
      }
    }

    // Update yCursor
    return yCursor - currentY + AFTER_TEXT_PADDING;
  }

  async drawTermsTextSection(
    page: PDFPage,
    font: PDFFont,
    yCursor: number
  ): Promise<number> {
    let currentY = yCursor;

    // Draw the text
    let termsBoxHeight = await this.createStructuredBox(
      page,
      SIDE_MARGIN,
      currentY,
      PAGE_WIDTH - 2 * SIDE_MARGIN,
      1,
      [[bolText.basic_terms]],
      [],
      '',
      '',
      '',
      0.5,
      TINY_FONT_SIZE,
      false
    );

    currentY -= termsBoxHeight;

    // Draw the text
    let liabilityBoxHeight = await this.createStructuredBox(
      page,
      SIDE_MARGIN,
      currentY,
      PAGE_WIDTH - 2 * SIDE_MARGIN,
      1,
      [[bolText.liability_statement]],
      [],
      '',
      '',
      '',
      0.5,
      TINY_FONT_SIZE,
      false,
      true
    );

    currentY -= liabilityBoxHeight;

    // Left Column Terms and Conditions

    const prepaidBoxWidth = (PAGE_WIDTH - 2 * SIDE_MARGIN) / 2;

    let prepaidBoxHeight = await this.createPrepaidBox(
      page,
      font,
      SIDE_MARGIN,
      currentY,
      prepaidBoxWidth
    );

    currentY -= prepaidBoxHeight;

    let classBoxHeight = await this.createSignatureBox(
      page,
      font,
      SIDE_MARGIN,
      currentY,
      prepaidBoxWidth,
      bolText.class_signature_text,
      bolText.class_signature_label,
      bolText.hazmat_text,
      8
    );

    currentY -= classBoxHeight;

    // Right Column Terms and Conditions

    currentY = yCursor - termsBoxHeight - liabilityBoxHeight;

    let prepaid3rdPartyHeight = await this.createStructuredBox(
      page,
      PAGE_WIDTH / 2,
      currentY,
      (PAGE_WIDTH - 2 * SIDE_MARGIN) / 2,
      1,
      [[bolText.prepaid3rdParty_text]],
      [],
      '',
      '',
      '',
      0.5,
      TINY_FONT_SIZE,
      false,
      true
    );

    currentY = currentY - prepaid3rdPartyHeight;

    let payCollectSignatureBoxHeight = await this.createSignatureBox(
      page,
      font,
      PAGE_WIDTH / 2,
      currentY,
      (PAGE_WIDTH - 2 * SIDE_MARGIN) / 2,
      bolText.payOnDeliveryText,
      bolText.payOnDeliveryLabel,
      undefined,
      9
    );

    return (
      termsBoxHeight +
      liabilityBoxHeight +
      Math.max(
        prepaidBoxHeight + classBoxHeight,
        prepaid3rdPartyHeight + payCollectSignatureBoxHeight
      )
    );
  }

  async createPrepaidBox(
    page: PDFPage,
    font: PDFFont,
    x: number,
    y: number,
    totalWidth: number
  ) {
    const boxHeight = await this.createStructuredBox(
      page,
      x,
      y,
      totalWidth,
      1,
      [[bolText.prepaid_text, '']],
      [],
      '',
      '',
      '',
      0.5,
      TINY_FONT_SIZE,
      false,
      true
    );

    // Draw a text line with a check box followed by the text "Check Box if Collect Shipment"
    const lineWidth =
      2 * TINY_FONT_SIZE +
      font.widthOfTextAtSize(bolText.collect_text, TINY_FONT_SIZE);
    const centeredX = x + (totalWidth - lineWidth) / 2;
    const lineY = y - boxHeight + BOXPADDING;

    // Draw a checkbox using a rectangle
    page.drawRectangle({
      x: centeredX,
      y: lineY,
      width: TINY_FONT_SIZE,
      height: TINY_FONT_SIZE,
      borderColor: rgb(0, 0, 0),
      borderWidth: 1,
    });

    // Draw the text next to the checkbox
    page.drawText(bolText.collect_text, {
      x: centeredX + 2 * TINY_FONT_SIZE,
      y: lineY,
      font: font,
      size: TINY_FONT_SIZE,
    });

    return boxHeight;
  }

  async createSignatureBox(
    page: PDFPage,
    font: PDFFont,
    x: number,
    y: number,
    totalWidth: number,
    descriptionText: string,
    signatureText: string,
    additionalText: string = '',
    minLines: number = 0
  ) {
    const boxHeight = await this.createStructuredBox(
      page,
      x,
      y,
      totalWidth,
      1,
      [[descriptionText, '', '', '']],
      [],
      '',
      '',
      '',
      0.5,
      TINY_FONT_SIZE,
      false,
      false,
      minLines * TINY_FONT_SIZE * LINE_FACTOR + 2 * BOXPADDING
    );

    // Draw a line with "Signature: " followed by a line for the signature. The signature line should be 1/3 the width of the box.
    const lineWidth = (4 / 5) * totalWidth;
    const lineLength =
      lineWidth - font.widthOfTextAtSize(signatureText, TINY_FONT_SIZE);
    const centeredX = x + (totalWidth - lineWidth) / 2;
    const lineY = y - boxHeight + BOXPADDING + TINY_FONT_SIZE * LINE_FACTOR;

    // Draw the "Signature: " text
    page.drawText(signatureText, {
      x: centeredX,
      y: lineY,
      font: font,
      size: TINY_FONT_SIZE,
    });

    // Draw the signature line
    page.drawLine({
      start: {
        x: centeredX + font.widthOfTextAtSize(signatureText, TINY_FONT_SIZE),
        y: lineY - 2,
      },
      end: { x: centeredX + lineWidth, y: lineY - 2 },
      thickness: 0.5,
      color: rgb(0, 0, 0),
    });

    const centeredHMX =
      x +
      (totalWidth -
        font.widthOfTextAtSize(bolText.hazmat_text, X_TINY_FONT_SIZE)) /
        2;

    page.drawText(additionalText, {
      x: centeredHMX,
      y: y - boxHeight + BOXPADDING,
      font: font,
      size: X_TINY_FONT_SIZE,
    });

    return boxHeight;
  }

  // HELPER METHODS

  async createStructuredBox(
    page: PDFPage,
    x: number,
    y: number,
    totalWidth: number,
    numCols: number,
    textGroups: string[][] = [],
    columnWidths: number[] = [],
    labelText: string = '',
    bottomText: string = '',
    topText: string = '',
    borderWidth: number = 0.5,
    fontSize: number = SMALL_FONT_SIZE,
    shaded: boolean = false,
    centered: boolean = false,
    minHeight: number = 0 // Minimum height of the box in points
  ) {
    const font = await page.doc.embedFont(StandardFonts.Helvetica);
    const boldFont = await page.doc.embedFont(StandardFonts.HelveticaBold);
    const lineHeight = fontSize * LINE_FACTOR;
    const labelLineHeight = TINY_FONT_SIZE * LINE_FACTOR; // Smaller font size for the label
    let contentStartX = x + BOXPADDING;
    if (labelText) {
      contentStartX += labelLineHeight;
    }
    let bottomTextLines: string[] = [];
    let topTextLines: string[] = [];

    let contentWidth = totalWidth;

    if (labelText) {
      contentWidth -= labelLineHeight;
    }

    // console.log('text groups:', textGroups);

    // console.log('columnWidths:', contentWidth);

    // Calculate column widths in pixels
    let columnWidthsInPixels: number[] = this.calculateColumnWidths(
      contentWidth,
      numCols,
      columnWidths,
      BOX_COL_PADDING
    );

    // console.log('columnWidthsInPixels:', columnWidthsInPixels);

    let cursorY = y;

    // console.log('TextGroups:', textGroups);

    // Split the text into lines for each column
    const columnLines = textGroups.map((textGroup) =>
      textGroup.flatMap((text) =>
        this.splitTextIntoLines(
          text,
          font,
          fontSize,
          columnWidthsInPixels[textGroups.indexOf(textGroup)]
        )
      )
    );
    // console.log('columnLines:', columnLines);

    if (bottomText) {
      bottomTextLines = this.splitTextIntoLines(
        bottomText,
        font,
        fontSize,
        contentWidth - 2 * BOXPADDING
      );
    }

    if (topText) {
      topTextLines = this.splitTextIntoLines(
        topText,
        font,
        fontSize,
        contentWidth - 2 * BOXPADDING
      );
    }

    // Calculate box height based on the content
    let textColNumLines = Math.max(...columnLines.map((lines) => lines.length));
    const boxHeight = Math.max(
      minHeight,
      textColNumLines * lineHeight +
        (bottomTextLines.length + topTextLines.length) *
          (lineHeight + AFTER_TEXT_PADDING) +
        2 * BOXPADDING
    );

    // Draw the box
    if (borderWidth > 0) {
      page.drawRectangle({
        x,
        y: y - boxHeight,
        width: totalWidth,
        height: boxHeight,
        borderColor: rgb(0, 0, 0),
        borderWidth,
        color: shaded ? rgb(0.95, 0.95, 0.95) : undefined, // Light grey if shaded, otherwise no color
      });
    }

    // Draw the label box and text
    if (labelText) {
      page.drawRectangle({
        x,
        y: y - boxHeight,
        width: labelLineHeight,
        height: boxHeight,
        color: rgb(0, 0, 0),
      });

      const labelTextHeight = boldFont.widthOfTextAtSize(
        labelText,
        TINY_FONT_SIZE
      );

      // Draw the rotated label text
      page.drawText(labelText, {
        x: x + TINY_FONT_SIZE,
        y: y - (boxHeight + labelTextHeight) / 2,
        rotate: degrees(90),
        font: boldFont,
        size: TINY_FONT_SIZE,
        color: rgb(1, 1, 1),
      });
    }

    // Draw bottom text
    if (topText) {
      topTextLines.forEach((lines) => {
        this.drawColumnText(
          page,
          [lines],
          contentStartX,
          cursorY,
          font,
          fontSize,
          lineHeight
        );
      });
      cursorY -= lineHeight + AFTER_TEXT_PADDING;
    }

    // Draw text in each column
    let currentX = contentStartX;
    columnLines.forEach((lines, colIndex) => {
      this.drawColumnText(
        page,
        lines,
        currentX,
        cursorY,
        font,
        fontSize,
        lineHeight,
        centered,
        columnWidthsInPixels[colIndex]
      );
      currentX += columnWidthsInPixels[colIndex] + BOX_COL_PADDING;
    });

    cursorY -= textColNumLines * lineHeight + AFTER_TEXT_PADDING;

    // Draw bottom text
    if (bottomText) {
      bottomTextLines.forEach((lines) => {
        this.drawColumnText(
          page,
          [lines],
          contentStartX,
          cursorY,
          font,
          fontSize,
          lineHeight
        );
      });
    }

    return boxHeight;
  }

  private createContactText(contact: contactObj, type: string): string[][] {
    // Handle Freight Charges info
    // console.log('contact:', contact);
    // If contact is undefined, use default contact object
    if (!contact) {
      contact = defaultContactObj;
    }
    const pickupLines = [
      `${type ? `${type}: ` : ''}${contact.companyname || 'Name'}`,
      contact.address || 'Address',
      `${contact.city || 'City'}, ${contact.state || 'ST'} ${
        contact.postal_code || 'ZIP'
      }`,
    ];
    // console.log('pickupLines:', pickupLines);
    const pickupContactLines = [
      `CONTACT: ${contact.contactname || 'Name'}`,
      `${contact.phone || 'Phone Number'}`,
    ];

    return [pickupLines, pickupContactLines];
  }

  private drawColumnText(
    page: PDFPage,
    lines: string[],
    x: number,
    cursorY: number,
    font: PDFFont,
    fontSize: number,
    lineHeight: number,
    centered: boolean = false,
    boxWidth: number = 0
  ) {
    let textY = cursorY - BOXPADDING - fontSize;
    lines.forEach((line) => {
      if (centered) {
        const lineWidth = font.widthOfTextAtSize(line, fontSize);
        const centeredX = x + (boxWidth - lineWidth) / 2;
        page.drawText(line, {
          x: centeredX,
          y: textY,
          font,
          size: fontSize,
        });
      } else {
        page.drawText(line, {
          x: x,
          y: textY,
          font,
          size: fontSize,
        });
      }
      textY -= lineHeight;
    });
  }

  private splitTextIntoLines(
    text: string,
    font: PDFFont,
    fontSize: number,
    maxWidth: number
  ): string[] {
    // If the text is a null or empty string, return an empty string
    if (!text) {
      return [''];
    }

    const words = text.split(' ');
    const lines: string[] = [];
    let currentLine = '';

    words.forEach((word) => {
      const testLine = currentLine ? `${currentLine} ${word}` : word;
      const testLineWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testLineWidth < maxWidth) {
        currentLine = testLine;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    });

    if (currentLine) {
      lines.push(currentLine);
    }

    return lines;
  }

  private calculateColumnWidths(
    totalWidth: number,
    numCols: number,
    columnWidths: number[],
    padding: number
  ): number[] {
    // Calculate column widths in pixels
    if (columnWidths.length === numCols) {
      const totalPercentage = columnWidths.reduce(
        (sum, value) => sum + value,
        0
      );
      if (totalPercentage !== 100) {
        throw new Error('Column widths must sum to 100%.');
      }
      return columnWidths.map(
        (percentage) =>
          (percentage / 100) *
          (totalWidth - 2 * BOXPADDING - (numCols - 1) * padding)
      );
    } else {
      // Default to even-width columns
      const evenWidth =
        (totalWidth - 2 * BOXPADDING - (numCols - 1) * padding) / numCols;
      return Array(numCols).fill(evenWidth);
    }
  }
}
