import { Injectable } from '@angular/core';
import { contactObj, ltlBookingObj } from '../interfaces';
import {
  AlertService,
  AmplifyApiGWService,
} from './';

@Injectable({
  providedIn: 'root',
})
export class LtlEmailService {
  constructor(
    private ajax: AmplifyApiGWService,
    private alertService: AlertService,
  ) {}

  getCurrencyString(value: number): string {
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });
  }

  createSaveRQEmailString(data: ltlBookingObj): string {
    let emailBody =
      '<pre width="300" style="font-size: 14px; max-width: 100%;">';

    emailBody += `Requester:
    Name:                   ${data.fullname || data.username}
    Company:                ${data.companyname}
    Email:                  ${data.useremail}`;

    emailBody += this.createAddressString('Ship From', data.origin);
    emailBody += this.createAddressString('Ship To', data.dest);

    let shipDetails = `${
      data.accessorials.length > 0
        ? `
    Accessorials:           ${data.accessorials.join(', ')}`
        : ''
    }${
      data.insValue && data.insValue > 0
        ? `
    Shipment Value:         ${this.getCurrencyString(data.insValue)}`
        : ''
    }`;

    emailBody += `${
      shipDetails
        ? `

Shipment Details:${shipDetails}`
        : ''
    }`;

    data.items.forEach((item, index) => {
      emailBody += `

Line Item ${index + 1}:
    Description:            ${item.description}
    HazMat:                 ${item.hazmat ? 'Yes' : 'No'}
    HazMat Code:            ${item.hazmatcode}
    Class:                  ${item.class}
    NMFC:                   ${item.nmfccode}
    Weight:                 ${item.weight}
    Handling Unit:          ${item.handlingUnit}
    Length:                 ${item.len}
    Width:                  ${item.width}
    Height:                 ${item.height}
    Number of Units:        ${item.numUnits}
    Package Type:           ${item.pkgType}
    Number of Packages:     ${item.numPkgs}
    Density:                ${item.density}
    Unit:                   ${item.unit}`;
    });

    emailBody += `

Quote Details:
    Carrier:                ${data.carriername}
    Service Code/Level:     ${data.servicecode}
    Transit Days:           ${data.transitdays}
    Charge:                 ${this.getCurrencyString(data.charge)}
    Insurance Requested:    ${
      data.insrequested
        ? `Yes
    Charge With Insurance:  ${this.getCurrencyString(
      data.chargewithinsurance || 0
    )}`
        : 'No'
    }
    Quote Number:           ${data.quotenumber}`;

    emailBody += '</pre>';
    return emailBody;
  }

  // Function to create the email body string
  createLTLShpmntEmailString(
    data: ltlBookingObj,
    userDefinedRefs: boolean,
    requestType: string = 'LTL'
  ): string {
    console.log('Constructing LTL Shipment email: ', data);
    let emailBody =
      '<pre width="300" style="font-size: 14px; max-width: 100%;">';

    emailBody += `Your LTL Booking Request has been received and is under review.
Please find the draft Bill of Lading attached to this email.
The bill of lading and pallet labels (if required) will be sent to you and the shipper/receiver if requested.
See full shipment details below:
`;

    emailBody += `
Requester:
    Name:                   ${data.fullname || data.username}
    Company:                ${data.companyname}
    Email:                  ${data.useremail}
    Date:                   ${new Date().toLocaleString()}`;

    emailBody += this.createAddressString('Ship From', data.origin);
    emailBody += this.createAddressString('Ship To', data.dest);
    if (data.billto) {
      emailBody += this.createAddressString('Bill To', data.billto);
    }

    // if data.accessorials[0].type.length > 1, then set shipDetails to 'Multiple'
    let shipDetails = `
    Pickup Ready Time:      ${data.pickuptime}
    Pickup Closing Time:    ${data.closetime}${
      data.accessorials.length > 0
        ? `
    Accessorials:           ${data.accessorials.join(', ')}`
        : ''
    }
    Shipment Value:         ${
      data.insValue && data.insValue > 0
        ? this.getCurrencyString(data.insValue)
        : '--'
    }
    Send BOL to Shipper:    ${data.sendBOLtoShipper ? 'Yes' : 'No'}
    Send BOL to Receiver:   ${data.sendBOLtoConsignee ? 'Yes' : 'No'}
    Request Pallet Labels:  ${data.reqPltLabels ? 'Yes' : 'No'}
    Bill to Receiver:       ${
      data.billToReceiver
        ? `Yes
    Receiver Acct Number:   ${data.receiverAcctNum}`
        : 'No'
    }${
      data.specialinst
        ? `
    Special Instructions:   ${data.specialinst}`
        : ''
    }`;

    emailBody += `${
      shipDetails
        ? `

Shipment Details:${shipDetails}`
        : ''
    }`;

    if (data.references && data.references.length > 0) {
      let refsAdded = false;
      let refString = `

References:`;

      data.references.forEach((ref, index) => {
        const refType = ref.refType ? ref.refType : '';
        const refNumber = ref.refNumber ? ref.refNumber : '';
        const refDescription = ref.refDescription ? ref.refDescription : '';

        if (refNumber !== '') {
          refsAdded = true;
          if (userDefinedRefs) {
            refString += `
    ${`${refType}:`.padEnd(23, ' ')} ${refNumber}`;
          } else {
            refString += `
    ${`${refDescription}:`.padEnd(23, ' ')} ${refNumber.padEnd(10, ' ')} ${
              ref.numPackages ? `${ref.numPackages} PKG` : ''
            }`;
          }
        }
      });
      if (refsAdded) {
        emailBody += refString;
      }
    }

    data.items.forEach((item, index) => {
      emailBody += `

Line Item ${index + 1}:
    Description:            ${item.description}
    HazMat:                 ${item.hazmat ? 'Yes' : 'No'}
    HazMat Code:            ${item.hazmatcode}
    Class:                  ${item.class}
    NMFC:                   ${item.nmfccode}
    Weight:                 ${item.weight}
    Handling Unit:          ${item.handlingUnit}
    Length:                 ${item.len}
    Width:                  ${item.width}
    Height:                 ${item.height}
    Number of Units:        ${item.numUnits}
    Package Type:           ${item.pkgType}
    Number of Packages:     ${item.numPkgs}
    Density:                ${item.density}
    Unit:                   ${item.unit}`;
    });

    if (requestType === 'LTL') {
      emailBody += `

Quote Details:
    Carrier:                ${data.carriername}
    Service Code/Level:     ${data.servicecode}
    Transit Days:           ${data.transitdays}
    Charge:                 ${this.getCurrencyString(data.charge)}
    Insurance Requested:    ${
      data.insrequested
        ? `Yes
    Charge With Insurance:  ${this.getCurrencyString(
      data.chargewithinsurance || 0
    )}`
        : 'No'
    }
    Quote Number:           ${data.quotenumber}`;
    }

    emailBody += '</pre>';
    return emailBody;
  }

  createAddressString(label: string, address: contactObj): string {
    let addressText = `${
      address.companyname
        ? `
    Company:                ${address.companyname}`
        : ''
    }${
      address.contactname
        ? `
    Contact:                ${address.contactname}`
        : ''
    }${
      address.address
        ? `
    Address:                ${address.address}`
        : ''
    }${
      address.address2
        ? `
                            ${address.address2}`
        : ''
    }${
      address.postal_code
        ? `
                            ${address.city ? `${address.city}, ` : ''}${
            address.state ? `${address.state} ` : ''
          }${address.postal_code}${
            address.country ? `  ${address.country}` : '  USA'
          }`
        : ''
    }`;

    addressText += `
    Residential?            ${address.isResidential ? 'Yes' : 'No'}${
      address.phone
        ? `
    Phone:                  ${address.phone}`
        : ''
    }${
      address.email
        ? `
    Email:                  ${address.email}`
        : ''
    }`;

    if (addressText.length > 0) {
      return `

${label}:${addressText}`;
    } else {
      return '';
    }
  }
}
