import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { Subscription, from } from 'rxjs';
import { throwError, Observable, BehaviorSubject, of } from 'rxjs';
import { AmplifyApiGWService, AuthenticationService } from './';
import { catchError, switchMap, map } from 'rxjs/operators';
import { contactObj, UserInfo, custInfo, ppItem } from '../interfaces';
import { PipeList } from 'aws-sdk/clients/pipes';

/**
 * Provides a wrapper around the browser's localStorage functionality,
 * offering typed methods for setting and getting values in a variety of formats.
 */
@Injectable({ providedIn: 'root' })
export class DataService {
  private subscriptions: Subscription[] = [];
  private showAllCustomers: boolean = false;
  private superUser: boolean = false;
  private userCompany: string = '';
  private userCompanyInfo: custInfo = {} as custInfo;
  private userParentCompanyInfo: custInfo = {} as custInfo;
  private userCompanies: string[] = [];
  private userCompanyList: custInfo[] = [];
  private pricing_programs: ppItem[] = [{ name: 'DEFAULT', value: 'DEFAULT' }];
  private support_email: string = 'freight@freightdesk.ai';
  private selectedPricingProgram: string = 'DEFAULT';
  private addressCustIDs: string = '';
  private userInfo: UserInfo = {} as UserInfo;
  loggedIn: boolean = false;

  private addressListSubject = new BehaviorSubject<contactObj[]>([]); // List of addresses for the "address book"
  addressList$ = this.addressListSubject.asObservable();

  private custCompanyListSubject = new BehaviorSubject<custInfo[]>([]); // List of companies the customer is associated with (primary company plus affiliated companies)
  custCompanyList$ = this.custCompanyListSubject.asObservable();

  private customerListSubject = new BehaviorSubject<custInfo[]>([]); // list of customers the user can see (affiliated companies and their customers)
  customerList$ = this.customerListSubject.asObservable();

  private custInfoSubject = new BehaviorSubject<custInfo>( // Information about the customer's primary company
    {} as custInfo
  );

  private parentCustInfoSubject = new BehaviorSubject<custInfo>( // Information about the customer company's parent company
    {} as custInfo
  );
  private supportEmailSubject = new BehaviorSubject<string>('');
  supportEmail$ = this.supportEmailSubject.asObservable();

  private pricingProgramSubject = new BehaviorSubject<ppItem[]>([]);
  pricingPrograms$ = this.pricingProgramSubject.asObservable();

  private selectedPricingProgramSubject = new BehaviorSubject<string>(
    'DEFAULT'
  );

  constructor(
    private authService: AuthenticationService,
    private apiService: AmplifyApiGWService
  ) {
    console.log('Data Service Initialized');
    this.subscriptions.push(
      this.authService.getUserDetails().subscribe((userDetails) => {
        if (this.loggedIn) {
          console.log('User Details = ', userDetails);
          this.userInfo = JSON.parse(JSON.stringify(userDetails));
          this.userCompany = userDetails.company;
          this.userCompanies = userDetails.companyList;
          this.support_email = userDetails.support_email;
          this.supportEmailSubject.next(this.support_email);

          // if 'Customers' is in userInfo.permissionList, set showAllCustomers to true
          if (userDetails.permissionsList.includes('Customers')) {
            this.showAllCustomers = true;
          }

          // if 'Super_user' is in userInfo.permissionList, set superUser to true
          if (userDetails.permissionsList.includes('Super_user')) {
            this.superUser = true;
          }

          this.setSelectedPP(userDetails.pricing_program);
          this.loadCustomersAndAddresses();
        }
      }),
      this.authService.isAuthenticated().subscribe((loggedIn) => {
        if (loggedIn) {
          this.loggedIn = true;
          if (this.userCompanies && this.userCompanies.length > 0) {
            this.loadCustomersAndAddresses();
          }
        } else {
          this.loggedIn = false;
          this.userInfo = {} as UserInfo;
          this.userCompany = '';
          this.userCompanies = [];
          this.support_email = '';
          this.addressListSubject.next([]);
          this.customerListSubject.next([]);
          this.setCustomerInfo({} as custInfo);
          this.setParentCompanyInfo({} as custInfo);
          this.supportEmailSubject.next('');
          this.pricingProgramSubject.next([]);
          this.setSelectedPP('DEFAULT');
        }
      })
    );
  }

  loadCustomersAndAddresses(): void {
    from(this.apiService.callApi('/catalogs/customers', 'GET'))
      .pipe(
        switchMap((customers: custInfo[]) => {
          // console.log('Customers = ', customers);
          let fullCustomerList: custInfo[] = JSON.parse(
            JSON.stringify(customers)
          );
          // console.log('Customers Handler Output = ', fullCustomerList);
          // Add every object in fullCustomerList where the 'name' value is in this.userCompanies (this needs to be case insensitive) to CustomerList
          this.userCompanyList = fullCustomerList.filter((obj: any) =>
            this.userCompanies
              .map((company) => company.toLowerCase())
              .includes(obj.name.toLowerCase())
          );
          console.log('User Company List = ', this.userCompanyList);
          this.publishCustCompanyList(this.userCompanyList);

          // Set primaryCustomer to the object in CustomerList where the 'name' value is the first name in this.userCompanies
          this.userCompanyInfo = this.userCompanyList.find(
            (obj) => obj.name.toLowerCase() === this.userCompany.toLowerCase()
          ) || {} as custInfo;
          console.log('Customer Info = ', this.userCompanyInfo);
          if (this.userCompanyInfo) {
            this.setCustomerInfo(this.userCompanyInfo);
            this.pricing_programs = this.userCompanyInfo.pricing_programs;

            // If the primary customer has pricing programs, sort them and set the default pricing program
            if (this.pricing_programs.length > 0) {
              this.pricingProgramSubject.next(
                this.sortPricingPrograms(this.pricing_programs)
              );
              this.setSelectedPP(
                this.pricing_programs.find(
                  (program) => program.name === 'DEFAULT'
                )?.value ?? 'DEFAULT'
              );
            }
          }

          this.userParentCompanyInfo = fullCustomerList.find(
            (obj: custInfo) =>
              obj.name.toLowerCase() ===
              this.userInfo.parent_company.toLowerCase()
          ) || {} as custInfo;
          console.log('Parent Customer = ', this.userParentCompanyInfo);
          // If parentCustomer is not null, set the parentCustIDString subject to the 'cust_id' value of parentCustomer
          if (this.userParentCompanyInfo) {
            this.setParentCompanyInfo(this.userParentCompanyInfo);
          }

          if (this.showAllCustomers) {
            if (this.superUser) {
              // If the user is a super user, they get to see data for all customers
              this.publishCustomerList(fullCustomerList);
              this.addressCustIDs = fullCustomerList
                .map((obj: any) => obj.cust_id)
                .join(',');
              // console.log('Address Customer IDs = ', this.addressCustIDs);
            } else {
              // If the user is not a super user but still sees customers, they get to see data for all customers that are in the userCompanies list as well as those companies customers
              // Let customerList be the list of companies in fullCustomerList where either the 'cust_id' or the 'parent_cust_id' is the cust_id for a company in the userCompanyList
              const userCompanyCustIDs = this.userCompanyList.map(
                (obj) => obj.cust_id
              );
              let customerList = fullCustomerList.filter(
                (obj: any) =>
                  userCompanyCustIDs.includes(obj.cust_id) ||
                userCompanyCustIDs.includes(obj.parent_cust_id)
              );

              this.publishCustomerList(customerList);
              this.addressCustIDs = customerList
                .map((obj: any) => obj.cust_id)
                .join(',');
              // console.log('Address Customer IDs = ', this.addressCustIDs);
            }
          } else {
            // In this case, the user can only see data for the companies they are directly associted with

            this.publishCustomerList(this.userCompanyList);
            this.addressCustIDs = this.userCompanyList
              .map((obj: any) => obj.cust_id)
              .join(',');
            // console.log('Address Customer IDs = ', this.addressCustIDs);
          }

          return from(
            this.apiService.callApi('/catalogs/entities', 'GET', {
              child_cust_id: this.addressCustIDs,
            })
          );
        }),
        catchError((error) => {
          console.error('Error fetching data', error);
          return of([]); // Provide a fallback or handle errors as needed
        })
      )
      .subscribe((addresses: any[]) => {
        // Convert all strings to uppercase and transform to contactObj structure
        const contactObjs: contactObj[] = addresses.map((item) => ({
          companyname: item.name.toUpperCase(),
          address: item.address.toUpperCase(),
          address2: item.address2 ? item.address2.toUpperCase() : '',
          city: item.city.toUpperCase(),
          state: item.state.toUpperCase(),
          postal_code: item.postal_code.toUpperCase(),
          country: 'USA', // Assuming country defaults to 'USA'
          isResidential: item.residential || false, // Default to false if not provided
          contactname: item.contact_name ? item.contact_name.toUpperCase() : '',
          phone: item.contact_phone || '',
          email: item.contact_email || '',
        }));

        // Sort the contact objects by company name
        contactObjs.sort((a, b) => a.companyname.localeCompare(b.companyname));

        console.log('Address List = ', contactObjs);

        // Emit the transformed list
        this.addressListSubject.next(contactObjs);
      });
  }

  publishCustCompanyList(companies: custInfo[]): void {
    // Sort the customers by name
    companies.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    this.custCompanyListSubject.next(companies);
  }

  publishCustomerList(customers: any[]): void {
    // Sort the customers by name
    customers.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    this.customerListSubject.next(customers);
  }

  setCustomerInfo(address: custInfo) {
    this.custInfoSubject.next(JSON.parse(JSON.stringify(address)));
  }

  getCustomerInfo(): Observable<custInfo> {
    return this.custInfoSubject.asObservable();
  }

  setParentCompanyInfo(address: custInfo) {
    this.parentCustInfoSubject.next(JSON.parse(JSON.stringify(address)));
  }

  getParentCompanyInfo(): Observable<custInfo> {
    return this.parentCustInfoSubject.asObservable();
  }

  saveAddressToDatabase(address: contactObj): void {
    from(
      this.apiService.callApi(
        '/catalogs/entities',
        'POST',
        {},
        {
          name: address.companyname,
          address: address.address,
          address_2: address.address2 || '',
          postal_code: address.postal_code,
          city: address.city,
          state: address.state,
          country: 'USA',
          residential: address.isResidential === true,
          contact_name: address.contactname,
          contact_phone: address.phone,
          contact_email: address.email,
          child_cust_id: this.userCompanyInfo.cust_id,
          cust_id: this.userParentCompanyInfo.cust_id,
          validated: true,
        }
      )
    ).subscribe((result) => {
      // Handle the result if needed
      console.log('Add Entity result: ', result);
    });
  }

  sortPricingPrograms(pricingPrograms: any[]) {
    let sortedPrograms = JSON.parse(JSON.stringify(pricingPrograms));
    let defaultProgram = pricingPrograms.find(
      (program) => program.name === 'DEFAULT'
    );

    if (!defaultProgram) {
      defaultProgram = { name: 'DEFAULT', value: 'DEFAULT' };
    }

    // Filter out 'DEFAULT', sort the remaining programs, and then add 'DEFAULT' back at the beginning
    sortedPrograms = sortedPrograms.filter(
      (program: { name: string; value: string }) => program.name !== 'DEFAULT'
    );
    sortedPrograms.sort((a: any, b: any) => a.name.localeCompare(b.name));
    sortedPrograms.unshift(defaultProgram);
    return sortedPrograms;
  }

  setSelectedPP(SelectedPP: string) {
    if (this.selectedPricingProgram !== SelectedPP) {
      this.selectedPricingProgram = SelectedPP;
      this.selectedPricingProgramSubject.next(SelectedPP);
    }
  }

  getSelectedPP(): Observable<string> {
    return this.selectedPricingProgramSubject.asObservable();
  }
}
