import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-address-confirmation-modal',
  templateUrl: './address-confirmation-modal.component.html',
  styleUrls: ['./address-confirmation-modal.component.scss'],
})
export class AddressConfirmationModalComponent implements OnInit {
  @Input() oldAddress: any;
  @Input() newAddress: any;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}
  
  // When user accepts the changes
  accept() {
    this.modalCtrl.dismiss({
      accepted: true,
    });
  }

  // When user rejects the changes
  reject() {
    this.modalCtrl.dismiss({
      accepted: false,
    });
  }
}
