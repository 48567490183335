<ion-header>
  <ion-toolbar>
    <ion-title class="fdSendRQTitle">{{ modalTitle }}</ion-title>
    <ion-buttons slot="end">
      <ion-button class="fdSendRQButton" shape="round" fill="solid" color="dark" (click)="closeModal()">
        Close
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="12" class="fdComponentHeader">
        <ion-label>Request Details</ion-label>
      </ion-col>
      <ion-col size="2" class="fdComponentLabel">
        <ion-label>Pickup Date</ion-label>
      </ion-col>
      <ion-col size="2" class="fdComponentLabel">
        <ion-label>Insurance Requested?</ion-label>
      </ion-col>
      <ion-col size="2" class="fdComponentLabel">
        <ion-label>Shipment Value</ion-label>
      </ion-col>
      <ion-col size="2" class="fdComponentLabel">
        <ion-label>Accessorials</ion-label>
      </ion-col>
      <ion-col size="4" class="fdComponentLabel">
        <ion-label>Special Instructions</ion-label>
      </ion-col>
      <ion-col size="2" class="fdComponentContent">
        <ion-label>{{ shipDate }}</ion-label>
      </ion-col>
      <ion-col size="2" class="fdComponentContent">
        <ion-label>{{ insRequested ? 'Yes' : 'No' }}</ion-label>
      </ion-col>
      <ion-col size="2" class="fdComponentContent">
        <ion-label>{{ getCurrencyString(insValue) }}</ion-label>
      </ion-col>
      <ion-col size="2" class="fdComponentContent">
        <ion-label>{{ serviceItemString }}</ion-label>
      </ion-col>
      <ion-col size="4" class="fdComponentContent">
        <ion-textarea placeholder="Enter any special instructions" fill="outline" rows="4"
          [(ngModel)]="specialInstructions"></ion-textarea>
      </ion-col>
    </ion-row>
    <ion-row class="fdRowSeparation">
      <ion-col size="12" class="fdComponentHeader">
        <ion-label>Shipment Items</ion-label>
      </ion-col>
      <ion-col size="3" class="fdComponentItemsHeader">
        <ion-label>Description</ion-label>
      </ion-col>
      <ion-col size="1" class="center-text fdComponentItemsHeader">
        <ion-label class="center-text">Class</ion-label>
      </ion-col>
      <ion-col size="1" class="center-text fdComponentItemsHeader">
        <ion-label class="center-text">NMFC</ion-label>
      </ion-col>
      <ion-col size="1" class="center-text fdComponentItemsHeader">
        <ion-label class="center-text">HM</ion-label>
      </ion-col>
      <ion-col size="1" class="center-text fdComponentItemsHeader">
        <ion-label class="center-text">HM Code</ion-label>
      </ion-col>
      <ion-col size="2" class="center-text fdComponentItemsHeader">
        <ion-label class="center-text">Dimensions</ion-label>
      </ion-col>
      <ion-col size="1" class="center-text fdComponentItemsHeader">
        <ion-label class="center-text">Wt</ion-label>
      </ion-col>
      <ion-col size="1" class="center-text fdComponentItemsHeader">
        <ion-label class="center-text">Amt</ion-label>
      </ion-col>
      <ion-col size="1" class="center-text fdComponentItemsHeader">
        <ion-label class="center-text">Pkgs</ion-label>
      </ion-col>
    </ion-row>
    <ion-row class="fdComponentRow fd-custom-border" *ngFor="let item of itemList; let i = index">
      <ion-col size="3" class="fdComponentItems">
        <ion-textarea placeholder="Item description" fill="outline" rows="1"
          [(ngModel)]="item.description"></ion-textarea>
      </ion-col>
      <ion-col size="1" class="center-text fdComponentItems">
        <ion-label>{{ item.class }}</ion-label>
      </ion-col>
      <ion-col size="1" class="center-text fdComponentItems">
        <ion-input placeholder="NMFC" fill="outline" [(ngModel)]="item.nmfccode"></ion-input>
      </ion-col>
      <ion-col size="1" class="center-text fdComponentItems">
        <ion-checkbox [(ngModel)]="item.hazmat"></ion-checkbox>
      </ion-col>
      <ion-col size="1" class="center-text fdComponentItems">
        <ion-input placeholder="HM Code" fill="outline" [(ngModel)]="item.hazmatcode"></ion-input>
      </ion-col>
      <ion-col size="2" class="center-text fdComponentItems">
        <ion-label>{{ item.len }}x{{ item.width }}x{{ item.height }} ({{
          item.unit
          }})</ion-label>
      </ion-col>
      <ion-col size="1" class="center-text fdComponentItems">
        <ion-label>{{ item.weight }}</ion-label>
      </ion-col>
      <ion-col size="1" class="center-text fdComponentItems">
        <ion-label>{{ item.numUnits }} ({{ item.handlingUnit }})</ion-label>
      </ion-col>
      <ion-col size="1" class="center-text fdComponentItems">
        <ion-label>{{ item.numPkgs }} ({{ item.pkgType }})</ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" size-sm="12" size-xl="6">
        <app-entity-card [entityType]="'Origin'" [contactObjInput]="shipperObj" [lockZip]="true" [includeContacts]="true"
          (contactChange)="handleShipperObjChangeEvent($event)"></app-entity-card>
      </ion-col>
      <ion-col size="12" size-sm="12" size-xl="6">
        <app-entity-card [entityType]="'Destination'" [contactObjInput]="consigneeObj" [lockZip]="true"
          [includeContacts]="true" (contactChange)="handleConsigneeObjChangeEvent($event)"></app-entity-card>
      </ion-col>
      <ion-col size="12" size-sm="12" size-xl="6" *ngIf="showBillTo">
        <app-entity-card [entityType]="'Bill Third Party To'" [contactObjInput]="billToObj" [lockZip]="false"
          [includeContacts]="true" (contactChange)="handleBillToObjChangeEvent($event)"></app-entity-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button class="fdSendRQButton" shape="round" fill="outline" color="primary" (click)="sendFTLRateQuoteRequest()">
        Request Shipment
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>