// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultRoute: 'index-home', 
  authParams: {
    userPoolId: 'us-east-2_00OFGN1Xn',
    userPoolClientId: '5aia4k3iskkeg09nog5r0eeom1',
    identityPoolId: 'us-east-2:e552837e-fed5-4a6d-9062-c9dd091cdb5e',
    region: 'us-east-2',
  },
  APIName: 'FreightDeskAPIDev',
  ApiKey: 'ciy52Jv2EwRrwiB1lWnwVLpwTughDCDGVgwxajdr',
  APIParams: {
    REST: {
      FreightDeskAPIDev: {
        endpoint: 'https://v5fij95dbe.execute-api.us-east-2.amazonaws.com/dev',
        region: 'us-east-2',
      },
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
