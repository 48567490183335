import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { Subscription, from } from 'rxjs';
import {
  AmplifyApiGWService,
  AuthenticationService,
  DefaultDataService,
  LtlBookService,
  ShipDetailService,
  ZipcodeService,
} from './';
import { SendFTLModalComponent } from '../components/send-ftl-modal/send-ftl-modal.component';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import {
  objItem,
  RateQuote,
  ltlBookingObj,
  contactObj,
} from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class RateQuoteService {
  private requestTypeSubject = new BehaviorSubject<string>('');

  private rqSource = new BehaviorSubject<RateQuote[]>([]);
  public rqList$ = this.rqSource.asObservable();

  private quotableSubject = new BehaviorSubject<boolean>(false);

  currentLTLBooking: ltlBookingObj = {} as ltlBookingObj;

  billToInfo: contactObj = this.defaultData.setContact();

  errorMessage: string = '';
  showCostStatus: boolean = false;
  showBillTo: boolean = false;
  username: string = '';
  useremail: string = '';
  userCompany: string = '';
  requestType: string = 'LTL';
  originInfo: contactObj = this.defaultData.setContact();
  destinationInfo: contactObj = this.defaultData.setContact();
  numItems: number = 0;
  shipDate: string = new Date().toISOString().split('T')[0];
  insRequested: boolean = false;
  insValue: number = 0;
  servicesList: string[] = [];
  itemList: objItem[] = [];
  rqList: any[] = [];
  sizeAlert: boolean = false;
  quoteInfo: RateQuote = {} as RateQuote;
  private subscriptions: Subscription[] = [];

  constructor(
    private http: HttpClient,
    private ajax: AmplifyApiGWService,
    private dataZip: ZipcodeService,
    private defaultData: DefaultDataService,
    private auth: AuthenticationService,
    private shipDetailService: ShipDetailService,
    private rqEmailDialog: ModalController,
    private router: Router,
    private ftlEmailDialog: ModalController,
    private alertController: AlertController,
    private ltlBookService: LtlBookService
  ) {
    this.subscriptions.push(
      this.auth.getUserDetails().subscribe((userDetails) => {
        this.username = userDetails.username;
        this.useremail = userDetails.email;
        this.userCompany = userDetails.company;
      }),
      this.auth.showCosts().subscribe((showCosts) => {
        this.showCostStatus = showCosts;
        this.showBillTo = showCosts;
      }),
      this.shipDetailService.getNumItems().subscribe((numItems: number) => {
        this.numItems = numItems;
        this.setQuotable();
      }),
      this.ltlBookService
        .currLTLBooking()
        .subscribe((ltlBookingObj: ltlBookingObj) => {
          this.currentLTLBooking = JSON.parse(JSON.stringify(ltlBookingObj));
          // if ltlBookingObj.pickupDate is not set, set shipdate to the today's date
          if (ltlBookingObj.pickupDate === '') {
            this.shipDate = new Date().toISOString().split('T')[0];
          } else {
            this.shipDate = ltlBookingObj.pickupDate;
          }
          this.insRequested = ltlBookingObj.insrequested;
          this.insValue = ltlBookingObj.insValue || 0;
          this.servicesList = ltlBookingObj.accessorials ?? [];
          this.itemList = ltlBookingObj.items;
          this.setQuotable();
        }),
      this.shipDetailService
        .getShipSizeAlert()
        .subscribe(async (sizeAlert: boolean) => {
          this.sizeAlert = sizeAlert;
          this.setQuotable();
        })
    );
  }

  setRequestType(requestType: string) {
    this.requestType = requestType;
    this.requestTypeSubject.next(requestType);
    this.setQuotable();
  }

  getRequestType(): Observable<string> {
    return this.requestTypeSubject.asObservable();
  }

  setOriginInfo(originInfo: contactObj) {
    this.originInfo = originInfo;
    this.ltlBookService.updateLTLOrigin(originInfo);
    this.setQuotable();
  }

  setDestinationInfo(destinationInfo: contactObj) {
    this.destinationInfo = destinationInfo;
    this.ltlBookService.updateLTLDestination(destinationInfo);
    this.setQuotable();
  }

  setQuotable() {
    if (this.sizeAlert && this.requestType === 'LTL') {
      this.quotableSubject.next(false);
      return;
    }

    if (this.numItems === 0) {
      this.quotableSubject.next(false);
      return;
    }

    // If insRequested is true, make sure insValue is a number greater than zero. Otherwise, return false.
    if (this.insRequested && (isNaN(this.insValue) || this.insValue <= 0)) {
      // console.log('Quotable trigger: insValue = ', this.insValue);
      this.quotableSubject.next(false);
      return;
    }

    // Make sure the zip code for both origin and destination are set to 5-digit strings
    if (
      this.originInfo.postal_code === '' ||
      this.destinationInfo.postal_code === ''
    ) {
      // console.log(
      //   'Quotable trigger: originInfo.postal_code = ',
      //   this.originInfo.postal_code
      // );
      this.quotableSubject.next(false);
      return;
    }
    if (
      this.originInfo.postal_code.length !== 5 &&
      this.originInfo.postal_code.length !== 10
    ) {
      // console.log(
      //   'Quotable trigger: originInfo.postal_code length = ',
      //   this.originInfo.postal_code.length
      // );
      this.quotableSubject.next(false);
      return;
    }
    if (
      this.destinationInfo.postal_code.length !== 5 &&
      this.destinationInfo.postal_code.length !== 10
    ) {
      // console.log(
      //   'Quotable trigger: destinationInfo.postal_code length = ',
      //   this.destinationInfo.postal_code.length
      // );
      this.quotableSubject.next(false);
      return;
    }
    this.quotableSubject.next(true);
    return;
  }

  isQuotable(): Observable<boolean> {
    return this.quotableSubject.asObservable();
  }

  resetRates() {
    this.rqList = [];
    this.rqSource.next([]);
  }

  loadSimpleFTLRequest(
    originInfo: contactObj,
    destinationInfo: contactObj,
    shipmentWeight: number,
    shipmentDetails: string,
    specialInstructions: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      //this.saveAddress();
      this.errorMessage = '';
      try {
        // Make sure the zip code for both origin and destination are set
        if (
          originInfo.postal_code === '' ||
          destinationInfo.postal_code === ''
        ) {
          this.errorMessage =
            'Please set the zip code for both origin and destination to continue.';
          throw 'Incomplete address';
        }
        // Make sure both the originInfo.postal_code and destinationInfo.postal_code are 5 digit numbers. If they are zip+4, then only take the first 5 digits
        originInfo.postal_code = originInfo.postal_code
          .replace(/\D/g, '')
          .substring(0, 5);
        destinationInfo.postal_code = destinationInfo.postal_code
          .replace(/\D/g, '')
          .substring(0, 5);

        let rqRequest = {
          requestType: 'FTL_simple', //
          Origin: {
            PickupDate: new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .split('T')[0],
            Address: originInfo.address,
            City: originInfo.city,
            LocationCode: 'ORG', //
            Country: originInfo.country,
            PostalCode: originInfo.postal_code + '',
            State: originInfo.state,
          },
          Destination: {
            DropOffDate: new Date(new Date().setDate(new Date().getDate() + 2))
              .toISOString()
              .split('T')[0],
            Address: destinationInfo.address,
            City: destinationInfo.city,
            LocationCode: 'ORG', //
            Country: destinationInfo.country,
            PostalCode: destinationInfo.postal_code + '',
            State: destinationInfo.state,
          },
          company: this.userCompany,
          username: this.username,
          Items: [
            {
              ItemDetail: {
                Weight: shipmentWeight,
              },
            },
          ],
          shipmentDetails,
          specialInstructions,
        };
        console.log('rqRequest = ', rqRequest);
        this.makeApiCall(rqRequest)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            console.error('Error: ', error);
            reject(error);
          });
      } catch (e) {
        reject(e); // Reject the promise if an exception is thrown
      }
    });
  }

  loadFTLRequest(
    originInfo: contactObj,
    destinationInfo: contactObj,
    shipDate: string,
    servicesList: string[],
    insValue: number,
    insRequested: boolean,
    currentItems: objItem[],
    specialInstructions: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      //this.saveAddress();
      this.errorMessage = '';
      try {
        // Make sure the zip code for both origin and destination are set
        if (
          originInfo.postal_code === '' ||
          destinationInfo.postal_code === ''
        ) {
          this.errorMessage =
            'Please set the zip code for both origin and destination to continue.';
          throw 'Incomplete address';
        }
        // Make sure both the originInfo.postal_code and destinationInfo.postal_code are 5 digit numbers. If they are zip+4, then only take the first 5 digits
        originInfo.postal_code = originInfo.postal_code
          .replace(/\D/g, '')
          .substring(0, 5);
        destinationInfo.postal_code = destinationInfo.postal_code
          .replace(/\D/g, '')
          .substring(0, 5);

        let rqRequest = {
          requestType: 'FTL',
          ServiceCode: servicesList,
          Origin: {
            PickupDate: shipDate,
            Address: originInfo.address,
            City: originInfo.city,
            LocationCode: 'ORG', //
            Country: originInfo.country,
            PostalCode: originInfo.postal_code + '',
            State: originInfo.state,
          },
          Destination: {
            DropOffDate: shipDate,
            Address: destinationInfo.address,
            City: destinationInfo.city,
            LocationCode: 'ORG', //
            Country: destinationInfo.country,
            PostalCode: destinationInfo.postal_code + '',
            State: destinationInfo.state,
          },
          shipmentvalue: insValue ? Math.ceil(insValue) : 0,
          insRequested: insRequested ? "Y": "N",
          Items: this.getItemsToWebService(currentItems),
          username: this.username,
          pricingProgram: this.userCompany,
          specialInstructions: specialInstructions,
        };

        console.log('rqRequest = ', rqRequest);
        this.makeApiCall(rqRequest)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            console.error('Error: ', error);
            reject(error);
          });
      } catch (e) {
        reject(e); // Reject the promise if an exception is thrown
      }
    });
  }

  getLTLRates(booking_key: number): Promise<RateQuote[]> {
    return new Promise((resolve, reject) => {
      //this.saveAddress();
      this.errorMessage = '';
      const currentLTLBooking = this.currentLTLBooking;
      try {
        let rqRequest = {
          booking_key: booking_key,
          requestType: 'LTL', //
          Direction: 'Dropship', //
          BillingType: 'PPA', //
          Carrier: 'Rateshop', //
          ServiceCode: this.currentLTLBooking.accessorials ?? [],
          Origin: {
            PickupDate: this.currentLTLBooking.pickupDate,
            Address: this.currentLTLBooking.origin.address,
            City: this.currentLTLBooking.origin.city,
            LocationCode: 'ORG', //
            Country: this.currentLTLBooking.origin.country,
            PostalCode: this.currentLTLBooking.origin.postal_code + '',
            State: this.currentLTLBooking.origin.state,
          },
          Destination: {
            DropOffDate: this.currentLTLBooking.todate || this.currentLTLBooking.pickupDate,
            Address: this.currentLTLBooking.dest.address,
            City: this.currentLTLBooking.dest.city,
            LocationCode: 'ORG', //
            Country: this.currentLTLBooking.dest.country,
            PostalCode: this.currentLTLBooking.dest.postal_code + '',
            State: this.currentLTLBooking.dest.state,
          },
          shipmentvalue: this.currentLTLBooking.insValue ? Math.ceil(this.currentLTLBooking.insValue) : 0,
          insRequested: this.currentLTLBooking.insrequested ? "Y": "N",
          Items: this.getItemsToWebService(this.currentLTLBooking.items),
          username: this.username,
          pricingProgram: this.currentLTLBooking.pricing_program,
        };
        console.log('rqRequest = ', rqRequest);
        this.makeApiCall(rqRequest)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            console.error('Error: ', error);
            reject(error);
          });
      } catch (e) {
        reject(e); // Reject the promise if an exception is thrown
      }
    });
  }

  // Function to make the API call
  makeApiCall(rqRequest: any): Promise<RateQuote[]> {
    return new Promise((resolve, reject) => {
      from(this.ajax.callApi('/ltl_rater', 'POST', {}, rqRequest)).subscribe(
        (result) => {
          if (result.hasOwnProperty('status')) {
            console.error('Error Response: ', result);
            reject(result);
            return;
          }
          // console.log the datatype for result
          result = JSON.parse(result) as RateQuote[];
          console.log('Result: ', result);
          // if any values for the 'transitdays' are strings, convert them to numbers or 1
          result.forEach((rq: RateQuote) => {
            rq['TransitDays'] = parseInt(rq['TransitDays'] as string) || 1;
          });
          this.rqSource.next(result);
          this.ltlBookService.updateBookingStatus('QUOTED');
          resolve(result);
        },
        (error) => {
          console.error('Error, try again', error);
          throw error;
        }
      );
    });
  }

  formatDateToWS(value: string): string | null {
    if (!value) {
      return null;
    }
    const [year, month, day] = value.split('-');
    if (month && day && year) {
      return `${month}/${day}/${year}`;
    } else {
      return null;
    }
  }

  getItemsToWebService(currentItems: objItem[] = []) {
    var data: any[] = [];
    for (let aux = 0; aux < currentItems.length; aux++) {
      data.push({
        ItemDetail: {
          Name: 'Equipment', //
          Class: currentItems[aux].class,
          Weight: currentItems[aux]['weight'],
          WeightUnits: 'lb', //
          Quantity:
            currentItems[aux]['numPkgs'] ?? currentItems[aux]['numUnits'],
          QuantityUnit: currentItems[aux]['numPkgs']
            ? currentItems[aux]['pkgType']
            : currentItems[aux]['handlingUnit'], //
        },
        ItemDimension: {
          HandlingUnit: currentItems[aux]['handlingUnit'],
          Quantity: currentItems[aux]['numUnits'],
          DimensionUnits: currentItems[aux]['unit'],
          Height: currentItems[aux]['height'],
          Width: currentItems[aux]['width'],
          Length: currentItems[aux]['len'],
        },
      });
    }
    return data;
  }

  async openFTLEmailDialog(
    requestType: string,
    shipDate: string,
    insRequested: boolean,
    insValue: number,
    serviceItems: string[],
    currentItems: objItem[]
  ) {
    const modal = await this.ftlEmailDialog.create({
      component: SendFTLModalComponent,
      cssClass: 'send-rq-modal-class',
      componentProps: {
        requestType: requestType,
        shipper: this.originInfo,
        consignee: this.destinationInfo,
        billTo: this.billToInfo,
        insRequested: insRequested,
        insValue: insValue,
        shipDate: shipDate,
        serviceItems: JSON.parse(JSON.stringify(serviceItems)),
        currentItems: JSON.parse(JSON.stringify(currentItems)),
        showBillTo: this.showBillTo,
      },
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();

    // console.log('The dialog was closed: ', result);
    if (data === 'Success sending email') {
      this.rqUserAlert(
        'Email Sent',
        'Your FTL request has been sent successfully. Our team will follow up shortly.'
      );
    } else if (data === 'Error sending email') {
      this.rqUserAlert(
        'Email Failed',
        'Failed to send email. Please try again later.'
      );
    }
  }

  async rqUserAlert(headerText: string, messageText: string): Promise<void> {
    // Show the dialog box
    const alert = await this.alertController.create({
      header: headerText,
      message: messageText,
      cssClass: 'rq-custom-alert',
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {
            console.log('Alert dismissed');
          },
        },
      ],
    });

    await alert.present();
  }
}
