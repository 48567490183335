import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DefaultDataService } from './default-data.service';
import { LtlBookService } from './ltl-book.service';
import { objItem } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class ShipDetailService {
  private numItemsSubject = new BehaviorSubject<number>(0);
  private sizeAlertSubject = new BehaviorSubject<boolean>(false);
  private overLenAlertSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private defaultData: DefaultDataService,
    private ltlBookService: LtlBookService
  ) {}

  checkItemList(itemList: objItem[]) {
    // Update the item list and number of items
    this.ltlBookService.addObjItems(itemList);

    // console.log("Item list: ", itemList);

    // set numItems to the sum of all of the 'amount' properties in the itemList
    let numItems = itemList.reduce((sum, item) => sum + (item.numUnits ?? 0), 0);

    this.numItemsSubject.next(numItems);

    // Calculate the total weight of items
    let totalWeight = itemList.reduce((sum, item) => sum + (item.weight ?? 0), 0);

    // Criteria: All item heights must be less than 98 inches
    let heightsLessThan98 = itemList.every((item) => (item.height ?? 0) <= 98);

    let palletsFit = false;

    if (numItems <= 6) {
      // Create a list of pallets as follows: for every item in the itemList, create 'amount' number of pallets with the item's len and width in an array [len, width]
      let pallets: [number, number][] = [];
      itemList.forEach((item) => {
        for (let i = 0; i < (item.numUnits ?? 0); i++) {
          pallets.push([item.len ?? 0, item.width ?? 0]);
        }
      });
      // console.log("Pallets: ", pallets);
      const [fit, configuration] = this.fitRectangles(144, 98, pallets);
      // console.log("Fit: ", fit);
      // console.log("Configuration: ", configuration);
      palletsFit = fit;
    }

    // Determine if the shipment size alert should be set based on the criteria
    if (
      heightsLessThan98 &&
      totalWeight < 10000 &&
      palletsFit
    ) {
      this.setShipSizeAlert(false);
    } else {
      this.setShipSizeAlert(true);
    }

    // Determine if any items are over 8 feet in length or width
    if (itemList.some((item) => (item.len ?? 0) >= 96) || itemList.some((item) => (item.width ?? 0) >= 96)) {
      this.setOverLenAlert(true);
    } else {
      this.setOverLenAlert(false);
    }
  }

  fitRectangles(LTL_length: number, LTL_width: number, pallets: [number, number][]): [boolean, [number, number, number, number, number][]] {
    const tryFit = (palletsOrder: [number, [number, number]][]): [boolean, [number, number, number, number, number][]] => {
      const fitConfiguration: [number, number, number, number, number][] = [];

      let x_1 = 0;
      let width_1 = LTL_width;
      let x_2 = 0;
      let width_2 = LTL_width;

      for (let [origIndex, pallet] of palletsOrder) {
        const [length, width] = pallet;
        if (x_1 === x_2) {
          if (length <= LTL_length - x_1) {
            fitConfiguration.push([origIndex, x_1, 0, length, width]);
            x_1 += length;
            width_1 = LTL_width;
            width_2 = LTL_width - width;
          } else {
            return [false, []];
          }
        } else if (x_1 < x_2) {
          if (length <= LTL_length - x_1 && width <= width_1) {
            fitConfiguration.push([origIndex, x_1, 0, length, width]);
            x_1 += length;
            if (x_1 > x_2) {
              width_1 = LTL_width;
              width_2 = LTL_width - width;
            }
          } else if (length <= LTL_length - x_2 && width <= width_2) {
            fitConfiguration.push([origIndex, x_2, LTL_width - width, length, width]);
            x_2 += length;
            width_1 = Math.min(width_1, LTL_width - width);
          } else {
            return [false, []];
          }
        } else {
          if (length <= LTL_length - x_2 && width <= width_2) {
            fitConfiguration.push([origIndex, x_2, LTL_width - width, length, width]);
            x_2 += length;
            if (x_2 > x_1) {
              width_2 = LTL_width;
              width_1 = LTL_width - width;
            }
          } else if (length <= LTL_length - x_1 && width <= LTL_width) {
            fitConfiguration.push([origIndex, x_1, 0, length, width]);
            x_1 += length;
            width_2 = Math.min(width_2, LTL_width - width);
          } else {
            return [false, []];
          }
        }
      }

      return [true, fitConfiguration];
    };

    const totalArea = pallets.reduce((sum, [l, w]) => sum + l * w, 0);
    const LTL_area = LTL_width * LTL_length;

    if (totalArea > LTL_area) {
      return [false, []];
    }

    for (const [l, w] of pallets) {
      if (w > LTL_width || l > LTL_length) {
        return [false, []];
      }
    }

    const indexedPallets = pallets.map((pallet, index) => [index, pallet] as [number, [number, number]]);
    const permutations = this.permute(indexedPallets);

    for (const perm of permutations) {
      // console.log("Item list permutation: ", perm);
      const [fit, configuration] = tryFit(perm);
      if (fit) {
        return [true, configuration];
      }
    }

    return [false, []];
  }

  private permute<T>(arr: T[]): T[][] {
    if (arr.length === 0) return [[]];
    const result: T[][] = [];
    for (let i = 0; i < arr.length; i++) {
      const rest = this.permute(arr.slice(0, i).concat(arr.slice(i + 1)));
      for (const perm of rest) {
        result.push([arr[i], ...perm]);
      }
    }
    return result;
  }

  getNumItems(): Observable<number> {
    return this.numItemsSubject.asObservable();
  }

  setShipSizeAlert(sizeAlert: boolean) {
    this.sizeAlertSubject.next(sizeAlert);
  }

  getShipSizeAlert(): Observable<boolean> {
    return this.sizeAlertSubject.asObservable();
  }

  setOverLenAlert(overLenAlert: boolean) {
    this.overLenAlertSubject.next(overLenAlert);
  }

  getOverLengthAlert(): Observable<boolean> {
    return this.overLenAlertSubject.asObservable();
  }

}
