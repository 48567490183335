export const validSystemTypes: string[] = ['WWE', 'DEFAULT'];

export type SystemType = 'WWE' | 'DEFAULT'; // Add more keys as needed

export const LTL_BOL_PAGE_CONFIG = {
    WWE: {
      modalTitle: 'Complete WWE LTL Shipment Request',
      userDefinedRefs: false,
      refTypeList: 'WWE',
      showNumPkgs: true,
      showThirdParty: false,
      allowBookShipment: false,
      isOriginPhoneRequired: true,
      isDestPhoneRequired: true,
      createBOLBtnLabel: 'Download Draft BOL',
      reqBookShpmntLabel: 'Book Shipment',
    },
    DEFAULT: {
      modalTitle: 'Complete LTL Shipment Request',
      userDefinedRefs: false,
      refTypeList: 'DEFAULT',
      showNumPkgs: false,
      showThirdParty: false,
      allowBookShipment: false,
      isOriginPhoneRequired: true,
      isDestPhoneRequired: true,
      createBOLBtnLabel: 'Download Draft BOL',
      reqBookShpmntLabel: 'Book Shipment',
    },
  };