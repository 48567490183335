import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { AddressConfirmationModalComponent } from '../components/address-confirmation-modal/address-confirmation-modal.component';

@Injectable({
  providedIn: 'root', // Makes the service available globally
})
export class AlertService {
  constructor(
    private alertController: AlertController,
    private modalController: ModalController
  ) {}

  async showAlert(message: string, header: string = 'Alert'): Promise<void> {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: ['OK'],
      cssClass: 'fd-custom-alert',
    });
    await alert.present();
  }

  // Helper function to show an alert
  showConfAlert(header: string, message: string): Promise<boolean> {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header: header,
        message: message,
        buttons: [
          {
            text: 'Confirm',
            handler: () => {
              resolve(true);
            },
          },
          {
            text: 'Fix',
            handler: () => {
              resolve(false);
            },
          },
        ],
        cssClass: 'fd-custom-alert',
      });
      await alert.present();
    });
  }

  // Helper function to show the confirmation modal for address changes
  async confirmAddressChange(
    oldAddress: any,
    newAddress: any,
    type: string
  ): Promise<boolean> {
    const modal = await this.modalController.create({
      component: AddressConfirmationModalComponent, // You need to have this modal component
      componentProps: {
        oldAddress: oldAddress,
        newAddress: newAddress,
      },
      cssClass: 'address-alert-modal', // Custom class for modal size
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();
    return data.accepted;
  }
}
